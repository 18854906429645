import {useLocation, Link} from 'react-router-dom'

export const HeaderTabs: React.FC = () => {
  const location = useLocation()

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-1 pb-1'>
        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/products/group' && 'active')
                }
                to='/products/group'
              >
                Group
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/products/ledger' && 'active')
                }
                to='/products/ledger'
              >
                Ledger
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/products/voucher-type' && 'active')
                }
                to='/products/voucher-type'
              >
                Voucher Type
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/products/currency' && 'active')
                }
                to='/products/currency'
              >
                Currency
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
