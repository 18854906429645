import {useFormik} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {User, createNewUser, getExistingUsers, modifyExistingUser} from './api'
import {toast} from 'react-hot-toast'
import Select, {MultiValue} from 'react-select'
import {getCompaniesList} from '../../../_metronic/layout/components/header/api'
import {Link, useLocation} from 'react-router-dom'
import {Loader} from '../product-master/loader'

const userSettingsSchema = Yup.object().shape({
  userId: Yup.string(),
  firstName: Yup.string().required('Name is required'),
  lastName: Yup.string(),
  emailId: Yup.string().email('Not a valid email').required('Email id is required'),
  password: Yup.string().required('Password is required'),
  role: Yup.string().oneOf(['admin', 'viewer']).required('Role is required'),
  region: Yup.string(),
  address: Yup.string(),
  countryCode: Yup.string().required('Country code is required'),
  mobile: Yup.number().required('Mobile is required'),
})

const initialValues: User = {
  userId: '',
  firstName: '',
  lastName: '',
  emailId: '',
  password: '',
  role: 'admin',
  region: '',
  address: '',
  countryCode: '',
  mobile: 0,
  isSuperAdmin: false,
  companyAccess: [],
}

type OptionType = {
  value: string
  label: string
}

export const Settings = () => {
  const [newUser, setNewUser] = useState(true)
  const [loading, setLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [companyOptions, setCompanyOptions] = useState<OptionType[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<MultiValue<OptionType>>()
  const [userOptions, setUserOptions] = useState<OptionType[]>([])
  const [userDetails, setUserDetails] = useState<Record<string, User>>()
  const [selectedUser, setSelectedUser] = useState<OptionType | undefined>(undefined)

  const getCompanyList = useCallback(async () => {
    try {
      setIsError(false)
      setLoading(true)
      const res = await getCompaniesList()

      const companies = Object.keys(res).map((cin) => ({
        label: res[cin].companyName,
        value: cin,
      }))
      companies && setCompanyOptions(companies as any)
    } catch (err) {
      setIsError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  const getUsersList = useCallback(async () => {
    try {
      setIsError(false)
      setLoading(true)
      const res = await getExistingUsers()
      const details: Record<string, User> = {}
      const options = res?.users.map((u) => {
        details[u.userId] = u
        return {
          value: u.userId,
          label: `${u.firstName} ${u.lastName} (${u.emailId})`,
        }
      })
      options && setUserOptions(options)
      details && setUserDetails(details)
    } catch (err) {
      setIsError(true)
    } finally {
      setLoading(false)
    }
  }, [setUserDetails])

  useEffect(() => {
    getCompanyList()
    getUsersList()
  }, [getCompanyList, getUsersList])

  const formik = useFormik<User>({
    initialValues,
    validationSchema: userSettingsSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      setIsError(false)
      setLoading(true)
      const companyAccess = selectedCompanies?.map((c) => c.value) || []
      const body = {...values, companyAccess}
      if (newUser) {
        await createNewUser(body)
          .then(() => {
            toast.success('New user has been created')
            formik.resetForm()
            setSelectedCompanies([])
            getUsersList()
          })
          .catch(() => setIsError(true))
          .finally(() => setLoading(false))
      } else {
        setIsError(false)
        setLoading(true)
        await modifyExistingUser({
          ...body,
          isSuperAdmin: false,
          password: body.password === 'somedummyvalue' ? null : body.password,
        })
          .then(() => {
            toast.success('User has been updated')
          })
          .catch(() => setIsError(true))
          .finally(() => setLoading(false))
      }
    },
  })

  useEffect(() => {
    async function setValue() {
      if (userDetails && selectedUser && userDetails?.[selectedUser.value])
        await formik.setValues(userDetails[selectedUser.value])
      formik.setFieldValue('password', 'somedummyvalue')
    }
    if (selectedUser?.value.length) setValue()
    else {
      formik.resetForm()
      setSelectedUser(undefined)
    }
  }, [selectedUser?.value])

  useEffect(() => {
    if (newUser) {
      formik.resetForm()
      setSelectedUser(undefined)
    }
  }, [newUser])

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-1 pb-1'>
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6 ` + (newUser && 'active')}
                  onClick={() => setNewUser(true)}
                >
                  Add User
                </div>
              </li>
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6 ` + (!newUser && 'active')}
                  onClick={() => setNewUser(false)}
                >
                  Modify User
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 '
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{newUser ? 'Add New User' : 'Modify Existing User'}</h3>
          </div>
        </div>

        {!loading && (
          <div className='card-body border-top p-9'>
            {!newUser && (
              <div className='row mb-6 pb-3 border-bottom'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Existing User</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <Select
                    placeholder='Select an existing user'
                    onChange={(option) => setSelectedUser(option as OptionType)}
                    value={selectedUser}
                    options={userOptions}
                    isSearchable={true}
                    components={{IndicatorSeparator: () => null}}
                  />
                </div>
              </div>
            )}
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>User Id</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    disabled={!newUser}
                    type='text'
                    className={`form-control form-control-lg form-control-solid ${
                      !newUser ? 'disabled' : ''
                    }`}
                    placeholder='User Id'
                    {...formik.getFieldProps('userId')}
                  />
                  {formik.touched.userId && formik.errors.userId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.userId}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Email Id</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='email'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Email Id'
                    {...formik.getFieldProps('emailId')}
                  />
                  {formik.touched.emailId && formik.errors.emailId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.emailId}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Person Name</span>
                </label>

                <div className='col-lg-4 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='First Name'
                    {...formik.getFieldProps('firstName')}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.firstName}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-4 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Last Name'
                    {...formik.getFieldProps('lastName')}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.lastName}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Password</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='password'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Password'
                    {...formik.getFieldProps('password')}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.password}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Role</span>
                </label>
                <div className='col-lg-8 fv-row d-flex'>
                  <span className='form-check form-check-custom form-check-solid fs-6'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='admin'
                      value='admin'
                      checked={formik.values.role === 'admin'}
                      onChange={() => formik.setFieldValue('role', 'admin')}
                      style={{marginRight: '10px'}}
                    />
                    Admin
                  </span>
                  <span
                    style={{marginLeft: '20px'}}
                    className='form-check form-check-custom form-check-solid ml-2 fs-6'
                  >
                    <input
                      className='form-check-input ml-2'
                      type='radio'
                      name='viewer'
                      value='viewer'
                      checked={formik.values.role === 'viewer'}
                      onChange={() => formik.setFieldValue('role', 'viewer')}
                      style={{marginRight: '10px'}}
                    />{' '}
                    Viewer
                  </span>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Region</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Region'
                    {...formik.getFieldProps('region')}
                  />
                  {formik.touched.region && formik.errors.region && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.region}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Address</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='textarea'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Address'
                    {...formik.getFieldProps('address')}
                  />
                  {formik.touched.address && formik.errors.address && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.address}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Country Code</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Country Code'
                    {...formik.getFieldProps('countryCode')}
                  />
                  {formik.touched.countryCode && formik.errors.countryCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.countryCode}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Mobile</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Mobile'
                    {...formik.getFieldProps('mobile')}
                  />
                  {formik.touched.mobile && formik.errors.mobile && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.mobile}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Company Access</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <Select
                    placeholder='Select Companies'
                    value={selectedCompanies}
                    onChange={(option) => setSelectedCompanies(option)}
                    options={companyOptions}
                    isSearchable={true}
                    isMulti={true}
                    components={{IndicatorSeparator: () => null}}
                  />
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-3'>
                <button
                  type='submit'
                  className='btn btn-primary ml-2'
                  disabled={loading || !formik.isValid || !selectedCompanies?.length}
                >
                  {!loading && (newUser ? 'Add User' : 'Save User')}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
        {loading && <Loader isError={isError} />}
      </div>
    </>
  )
}
