import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface ProductGroup {
  dbId?: number
  classification: string
  parentGroup: string
  subGroup: null | string
  isParentGroup?: boolean
  isEditable: boolean
}

export const getProductGroups = async (companyId: string): Promise<ProductGroup[] | undefined> => {
  return await axios
    .get(`${API_URL}/product-master/group/${companyId}`)
    .then((response: AxiosResponse<ProductGroup[]>) => response.data)
    .then((response: ProductGroup[]) => response)
}

export interface Record {
  dbId?: number
  classification: string
  parentGroup: string
  subGroup: null | string
  isParentGroup?: boolean
  isEditable: boolean
}

export const downloadPdf = async (
  records: Record[],
  companyId: string,
  fyYear: string
): Promise<any | undefined> => {
  return await axios.post<any>(`${API_URL}/pdfDownload`, {
    records,
    companyId,
    fyYear,
  })
}

export interface CreateProductGroupBody {
  dbId?: number
  companyId: string
  classification: string
  parentGroup: string
  subGroup?: string
}

export const createProductGroup = async (
  body: CreateProductGroupBody
): Promise<{detail: string} | undefined> => {
  return await axios
    .post(`${API_URL}/product-master/group/save`, body)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export const deleteProductGroup = async (
  companyId: string
): Promise<{detail: string} | undefined> => {
  return await axios
    .delete(`${API_URL}/product-master/group/${companyId}`)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}
