import {useEffect} from 'react'
import {useLocation, Link} from 'react-router-dom'

const paths = [
  {title: 'Journal', path: '/transactions/view/journal'},
  {title: 'Receipt', path: '/transactions/view/receipt'},
  {title: 'Payment', path: '/transactions/view/payment'},
  {title: 'Contra', path: '/transactions/view/contra'},
  {title: 'Sales', path: '/transactions/view/sales'},
  {title: 'Purchase', path: '/transactions/view/purchase'},
  {title: 'Debit Note', path: '/transactions/view/debitnote'},
  {title: 'Credit Note', path: '/transactions/view/creditnote'},
]

export const HeaderTabs: React.FC<{setActiveTab: React.Dispatch<React.SetStateAction<string>>}> = ({
  setActiveTab,
}) => {
  const location = useLocation()

  useEffect(() => {
    setActiveTab(location.pathname.split('/')[3] as string)
  }, [location.pathname, setActiveTab])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-1 pb-1'>
        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {paths.map((p, i) => (
              <li key={i} className='nav-item'>
                <Link
                  key={p.path}
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === p.path && 'active')
                  }
                  onClick={() => setActiveTab(p.path.split('/')[3] as string)}
                  to={p.path}
                >
                  {p.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
