
import { useState } from "react";
import { ProductVoucher, createProductVoucher } from "./api";
import { useLayout } from "../../../../_metronic/layout/core";
import { KTIcon } from "../../../../_metronic/helpers";
import toast from "react-hot-toast";

export const CreateEditVoucher: React.FC<{
  setCreateVoucher: React.Dispatch<React.SetStateAction<boolean>>,
  editVoucher?: ProductVoucher
}>
  = ({ setCreateVoucher, editVoucher }) => {
    const { company } = useLayout();
    const [loading, setLoading] = useState(false);
    const [voucherType, setVoucherType] = useState(editVoucher?.voucherType ? editVoucher.voucherType : '');
    const [voucherName, setVoucherName] = useState(editVoucher ? editVoucher.voucherName : '');

    const handleCreateNewGroup = async () => {
      setLoading(true);
      createProductVoucher({
        dbId: editVoucher?.dbId,
        companyId: company?.value || '',
        voucherType,
        voucherName
      })
        .then((res) => {
          res?.detail && toast.success(res.detail);
          setVoucherType('');
          setVoucherName('');
        })
        .catch((err) => err && toast.error(err))
        .finally(() => setLoading(false));
    };

    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >

          <div className='card-title m-0'>
            <div
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title='Go Back'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              style={{ marginRight: '5px' }}
              onClick={() => setCreateVoucher(false)}
            >
              <KTIcon iconName='arrow-left' className='fs-2' />
            </div>
            <h3 className='fw-bolder m-0'>{!!editVoucher ? 'Update Voucher Type' : 'Add New Voucher Type'}</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Inbuilt Voucher Type</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  value={voucherType}
                  onChange={(e) => setVoucherType(e.currentTarget.value)}
                >
                  <option value=''>Select a Voucher Type...</option>
                  <option value='Sales'>Sales</option>
                  <option value='Purchase'>Purchase</option>
                  <option value='Receipt'>Receipt</option>
                  <option value='Payment'>Payment</option>
                  <option value='Journal'>Journal</option>
                  <option value='Contra'>Contra</option>
                  <option value='Credit Note'>Credit Note</option>
                  <option value='Debit Note'>Debit Note</option>

                </select>

              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Voucher Name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Voucher name'
                  value={voucherName}
                  onChange={(e) => setVoucherName(e.target.value)}
                />
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button className="btn btn-secondary" style={{ marginRight: '10px' }} onClick={() => setCreateVoucher(false)} >Cancel</button>
            <button onClick={handleCreateNewGroup} className='btn btn-primary ml-2' disabled={loading || !voucherName.length || !voucherType.length}>
              {!loading && !!editVoucher ? 'Update Voucher Type' : 'Add Voucher Type'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

        </div>
      </div>
    )
  }