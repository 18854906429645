import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {KTCard, KTCardBody, KTIcon, useDebounce} from '../../../../_metronic/helpers'
import {Loader} from '../loader'
import {getCurrencyColumns} from './columns'
import {Currency, getCurrency, downloadPdf} from './api'
import clsx from 'clsx'
import {useLayout} from '../../../../_metronic/layout/core'
import {CreateEditCurrency} from './CreateEditCurrency'
import {toast} from 'react-hot-toast'
import {AddExchangeRate} from './AddExchangeRate'
import jsPDF from 'jspdf'

const styles = {
  borderRadius: '0.475rem',
  boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
  backgroundColor: '#fff',
  color: '#7e8299',
  fontWeight: '500',
  margin: '0',
  width: 'auto',
  padding: '1rem 2rem',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 4rem)',
}

export const CurrencyExchange = () => {
  const {company, financialYear} = useLayout()

  const tableRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState<Currency[]>([])
  const [createCurrency, setCreateCurrency] = useState(false)
  const [addExchangeRate, setAddExchangeRate] = useState(false)
  const [editCurrency, setEditCurrency] = useState<Currency | undefined>(undefined)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const columns = useMemo(() => getCurrencyColumns(setCreateCurrency, setEditCurrency), [])

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const filteredData: Currency[] = useMemo(() => {
    if (debouncedSearchTerm?.length) {
      return data.filter(
        (d) =>
          d.currencyCode.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d.formalName.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d.currencySymbol.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      )
    }
    return []
  }, [debouncedSearchTerm, data])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: debouncedSearchTerm?.length ? filteredData : data,
  })

  const getAllCurrency = useCallback(async () => {
    if (company) {
      try {
        setIsError(false)
        setIsLoading(true)
        const res = await getCurrency(company?.value)
        res && setData(res)
      } catch (err) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }
  }, [company])

  useEffect(() => {
    if (!createCurrency) {
      getAllCurrency()
      setEditCurrency(undefined)
    }
  }, [getAllCurrency, createCurrency])

  return (
    <KTCard>
      {createCurrency ? (
        <CreateEditCurrency setCreateCurrency={setCreateCurrency} editCurrency={editCurrency} />
      ) : addExchangeRate ? (
        <AddExchangeRate setAddExchangeRate={setAddExchangeRate} />
      ) : (
        <>
          <div className='card-header border-0 pt-6'>
            <div className='card-title'>
              {/* begin::Search */}
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder='Search'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              {/* end::Search */}
            </div>
            {/* begin::Card toolbar */}
            <div className='card-toolbar'>
              {/* begin::Group actions */}
              {
                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                  <button
                    type='button'
                    className='btn btn-primary me-2'
                    disabled={!data.length}
                    onClick={async () => {
                      toast.success('Download has been started successfully')
                      await downloadPdf(data, company.value, financialYear.value)
                      const doc = new jsPDF({
                        format: 'a2',
                        unit: 'px',
                        hotfixes: ['px_scaling'],
                      })

                      tableRef.current &&
                        doc.html(tableRef.current, {
                          callback: (doc) => {
                            const pageWidth = doc.internal.pageSize.getWidth()
                            const pageHeight = doc.internal.pageSize.getHeight()

                            const header = `${company.label} | ${financialYear.value}`
                            doc.setFontSize(26)
                            doc.setFont('Arial', 'italic', 600)
                            doc.text(header, (pageWidth - 300) / 2, 30)

                            const footer = `${new Date().getFullYear().toString()}© String Labs`
                            doc.setFontSize(20)
                            doc.text(footer, 30, pageHeight - 20 - 10)
                            const totalPages = doc.getNumberOfPages()

                            doc.deletePage(totalPages - 1)
                            doc.deletePage(totalPages - 2)
                            doc.deletePage(totalPages - 4)
                            doc.deletePage(totalPages - 3)

                            doc.save(
                              `Product Master - Currency - ${company.label}(${financialYear.value})`
                            )
                          },
                          margin: [80, 30, 30, 30],
                          autoPaging: 'text',
                        })
                    }}
                  >
                    <KTIcon iconName='arrow-down' className='fs-2' />
                    Download
                  </button>

                  <button
                    type='button'
                    className='btn btn-warning me-3'
                    onClick={() => {
                      if (data.length <= 1)
                        toast.error('Please add more currency to Add Exchange Rate for it')
                      else setAddExchangeRate(true)
                    }}
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                    Add Exchange Rate
                  </button>

                  {/* begin::Add user */}
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={() => setCreateCurrency(true)}
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                    Add Currency
                  </button>
                  {/* end::Add user */}
                </div>
              }
              {/* end::Group actions */}
            </div>
            {/* end::Card toolbar */}
          </div>
          <KTCardBody className='py-4'>
            {!isLoading && (
              <div className='table-responsive'>
                <table
                  ref={tableRef}
                  id='pm_currency_table'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                  {...getTableProps()}
                >
                  <thead>
                    <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                      {headers.map((column: ColumnInstance<Currency>) => (
                        <CustomHeaderColumn key={column.id} column={column} />
                      ))}
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                      rows.map((row: Row<Currency>, i) => {
                        prepareRow(row)
                        return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                      })
                    ) : (
                      <tr>
                        <td colSpan={7}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No matching records found
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {isLoading && <Loader isError={isError} />}
          </KTCardBody>
        </>
      )}
    </KTCard>
  )
}

type ColProps = {
  column: ColumnInstance<Currency>
}

export const CustomHeaderColumn: React.FC<ColProps> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

type RowProps = {
  row: Row<Currency>
}

const CustomRow: React.FC<RowProps> = ({row}) => (
  <tr {...row.getRowProps()} className='bg-hover-light'>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
        >
          {cell.render('Cell')}
        </td>
      )
    })}
  </tr>
)
