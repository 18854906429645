/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {/* Company */}
      <SidebarMenuItem to='/companies' icon='briefcase' title='Company' fontIcon='bi-layers' />

      {/* Product Master */}
      <SidebarMenuItem to='/products' icon='star' title='Product Master' fontIcon='bi-layers' />

      {/* Accounts */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Accounting Master
          </span>
        </div>
      </div>
      {/* <SidebarMenuItemWithSub
        to='/transactions'
        title='Accounting Master'
        fontIcon='bi-archive'
        icon='element-plus'
      > */}
      <SidebarMenuItem to='/transactions/enter' title='Enter Transactions' icon='row-horizontal' />

      <SidebarMenuItem to='/transactions/view' title='View Transactions' icon='eye' />

      <SidebarMenuItem
        to='/transactions/trial-balance'
        title='Trial Balance'
        icon='finance-calculator'
      />

      <SidebarMenuItem to='/transactions/aging-report' title='Aging Report' icon='chart-simple' />

      <SidebarMenuItem to='/profitandloss' title='P&L Report' icon='chart-pie-simple' />

      <SidebarMenuItem to='/balancesheet' title='Balance Sheet' icon='book' />

      <SidebarMenuItem to='/register' title='Register' icon='book-open' />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>General</span>
        </div>
      </div>

      <SidebarMenuItem to='/settings' icon='setting-2' title='Settings' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Import Data</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/uploadRecords'
        icon='add-files'
        title='Upload Records'
        fontIcon='bi-layers'
      />
    </>
  )
}

export {SidebarMenuMain}
