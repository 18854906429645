import {useEffect, useMemo, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useLayout} from '../../../_metronic/layout/core'
import {getParticularsValues} from './api'
import {KTCard, KTCardBody, KTIcon} from '../../../_metronic/helpers'
import {ColumnInstance, Row, useTable} from 'react-table'
import clsx from 'clsx'
import {getParticularsColumns} from './Columns'
import {Loader} from '../product-master/loader'

export const Particulars = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {company, financialYear} = useLayout()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState<any[]>([])

  const {voucher_type, particulars, from, to, view} = useMemo(() => {
    const loc = location?.state as {
      activeTab: string
      particulars: string
      from: string
      to: string
      view: string
    }
    return {
      voucher_type: loc.activeTab,
      particulars: loc.particulars,
      from: loc.from,
      to: loc.to,
      view: loc.view,
    }
  }, [location.state])

  useEffect(() => {
    const getData = () => {
      if (company?.value && financialYear?.value && from && to && particulars && voucher_type) {
        setIsError(false)
        setIsLoading(true)
        getParticularsValues(
          voucher_type,
          company?.value,
          financialYear.value,
          view,
          particulars,
          from,
          to
        )
          .then((res) => {
            res && setData(res)
            setIsError(true)
          })
          .finally(() => setIsLoading(false))
      }
    }

    getData()
  }, [company?.value, financialYear.value, from, particulars, to, view, voucher_type])

  const columns = useMemo(
    () => getParticularsColumns(voucher_type, from, to, view),
    [from, to, view, voucher_type]
  )

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <div
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title='Go Back'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              style={{marginRight: '5px'}}
              onClick={() => navigate(-1)}
            >
              <KTIcon iconName='arrow-left' className='fs-2' />
            </div>

            <div className='text-dark fw-bolder fs-2 my-1 fw-bolder'>
              <div>
                {particulars} -
                <span style={{textTransform: 'capitalize'}} className='text-muted '>
                  {voucher_type}
                </span>
              </div>
            </div>
          </div>
          {/* begin::Card toolbar */}
          <div className='card-toolbar'>
            {/* begin::Group actions */}
            {<div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'></div>}
            {/* end::Group actions */}
          </div>
          {/* end::Card toolbar */}
        </div>
        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='register_table'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              {...getTableProps()}
            >
              <thead>
                <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                  {headers.map((column: ColumnInstance<any>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<any>, i) => {
                    prepareRow(row)
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                  })
                ) : (
                  <tr>
                    <td colSpan={14}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {isLoading && <Loader isError={isError} />}
        </KTCardBody>
      </KTCard>
    </>
  )
}

type ColProps = {
  column: ColumnInstance<any>
}

export const CustomHeaderColumn: React.FC<ColProps> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

type RowProps = {
  row: Row<any>
}

export const CustomRow: React.FC<RowProps> = ({row}) => {
  return (
    <>
      <tr {...row.getRowProps()} className='bg-hover-light'>
        {row.cells.map((cell) => {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
            >
              {cell.render('Cell')}
            </td>
          )
        })}
      </tr>
    </>
  )
}

export const styles = {
  borderRadius: '0.475rem',
  boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
  backgroundColor: '#fff',
  color: '#7e8299',
  fontWeight: '500',
  margin: '0',
  width: 'auto',
  padding: '1rem 2rem',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 4rem)',
}
