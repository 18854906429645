import React, {useState} from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {HeaderTabs} from './HeaderTabs'
import {Transaction} from './Transaction'
import {VoucherTypeMap} from '../API/EnterTransactionsAPI'
import Preloader from '../../loaders/Preloader'

const EnterTransactions: React.FC = () => {
  const [activeTab, setActiveTab] = useState<keyof VoucherTypeMap>('journal')

  return (
    <Routes>
      <Route
        element={
          <>
            {/* <Preloader /> */}
            <HeaderTabs setActiveTab={setActiveTab} />
            <Transaction activeTab={activeTab} />
            <Outlet />
          </>
        }
      >
        <Route path='journal' element={<></>} />
        <Route path='receipt' element={<></>} />
        <Route path='payment' element={<></>} />
        <Route path='contra' element={<></>} />
        <Route path='sales' element={<></>} />
        <Route path='purchase' element={<></>} />
        <Route path='debitnote' element={<></>} />
        <Route path='creditnote' element={<></>} />
        <Route index element={<Navigate to='/transactions/enter/journal' />} />
      </Route>
    </Routes>
  )
}

export default EnterTransactions
