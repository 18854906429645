import {toAbsoluteUrl} from '../../../_metronic/helpers'
import * as earth from '../loaders/Animation - 1716795499887.json'
import Lottie from 'lottie-react'

const Loader = ({isError}: {isError: boolean}) => {
  const styles = {
    borderRadius: '0.475rem',
    // boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    // height: '100%',
    padding: '2rem 2rem',
    // top: 'calc(50% - 2rem)',
    // left: 'calc(50% - 4rem)',
    left: '0',
    top: '0',
    // bottom: '0',
    right: '0',
  }

  return (
    <div
      style={{
        ...styles,
        position: 'absolute',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        // height: '100%',
      }}
    >
      {!isError ? (
        <Lottie
          animationData={earth}
          loop
          autoPlay
          style={{
            height: '30rem',
            // width: '100%',
          }}
        />
      ) : (
        <>
          {/* begin::Title */}
          <h1 className='fw-bolder fs-2qx text-gray-900 mb-4'>System Error</h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='fw-semibold fs-6 text-gray-500 mb-7'>
            Something went wrong! Please try again later.
          </div>
          {/* end::Text */}

          {/* begin::Illustration */}
          <div>
            <img
              src={toAbsoluteUrl('/media/auth/500-error.png')}
              className='mw-100 mh-300px theme-light-show'
              alt=''
            />
            <img
              src={toAbsoluteUrl('/media/auth/500-error-dark.png')}
              className='mw-100 mh-300px theme-dark-show'
              alt=''
            />
          </div>
          {/* end::Illustration */}
        </>
      )}
    </div>
  )
}

export {Loader}
