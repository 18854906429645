/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ListsWidget26} from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <ListsWidget26
          className='h-lg-auto mb-6'
          title='Company'
          rows={[{description: 'Company List', url: '/companies'}]}
        />
        <ListsWidget26
          className='h-lg-auto mb-6'
          title='Product Master'
          rows={[
            {description: 'Group', url: '/products/group'},
            {description: 'Ledger', url: '/products/ledger'},
            {description: 'Voucher Type', url: '/products/voucher-type'},
            {description: 'Currency', url: '/products/currency'},
          ]}
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <ListsWidget26
          className='h-lg-90 mb-6'
          title='Enter Transactions'
          rows={[
            {description: 'Journal', url: '/transactions/enter/journal'},
            {description: 'Receipt', url: '/transactions/enter/receipt'},
            {description: 'Payment', url: '/transactions/enter/payment'},
            {description: 'Contra', url: '/transactions/enter/contra'},
            {description: 'Sales', url: '/transactions/enter/sales'},
            {description: 'Purchase', url: '/transactions/enter/purchase'},
            {description: 'Debit Note', url: '/transactions/enter/debitnote'},
            {description: 'Credit Note', url: '/transactions/enter/creditnote'},
          ]}
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <ListsWidget26
          className='h-lg-auto mb-6'
          title='Reports'
          rows={[
            {description: 'Trial Balance', url: '/transactions/trial-balance'},
            {description: 'Aging Report', url: '/transactions/aging-report/'},
            {description: 'P&L Report', url: '/profitandloss'},
            {description: 'Balance Sheet', url: '/balancesheet'},
          ]}
        />

        <ListsWidget26
          className='h-lg-auto '
          title='Settings'
          rows={[{description: 'User Settings', url: '/settings'}]}
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <ListsWidget26
          className='h-lg-85 mb-6'
          title='View Transactions'
          rows={[
            {description: 'Journal', url: '/transactions/view/journal'},
            {description: 'Receipt', url: '/transactions/view/receipt'},
            {description: 'Payment', url: '/transactions/view/payment'},
            {description: 'Contra', url: '/transactions/view/contra'},
            {description: 'Sales', url: '/transactions/view/sales'},
            {description: 'Purchase', url: '/transactions/view/purchase'},
            {description: 'Debit Note', url: '/transactions/view/debitnote'},
            {description: 'Credit Note', url: '/transactions/view/creditnote'},
          ]}
        />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
