// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from '../../apps/user-management/users-list/table/columns/UserCustomHeader'
import {ProductVoucher, deleteProductVoucher} from './api'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import {toast} from 'react-hot-toast'
import {useState} from 'react'

const getVoucherColumns = (
  setCreateVoucher,
  setEditVoucher
): ReadonlyArray<Column<ProductVoucher>> => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Voucher Type'
        className='min-w-125px ps-4 text-center'
      />
    ),
    accessor: 'voucherType',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Voucher Name'
        className='min-w-125px text-center'
      />
    ),
    accessor: 'voucherName',
    Cell: ({...props}) => <div>{props.data[props.row.index].voucherName || '-'}</div>,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='min-w-125px text-center' />
    ),
    accessor: 'actions',
    Cell: ({...props}) => {
      const [showDeleteModal, setShowDeleteModal] = useState(false)

      const handleEdit = () => {
        if (props.data[props.row.index].isEditable) {
          setCreateVoucher(true)
          setEditVoucher(props.data[props.row.index])
        }
      }

      const handleDelete = async () => {
        deleteProductVoucher(props.data[props.row.index].dbId)
          .then((res) => res?.detail && toast.success(res.detail))
          .catch((err) => err && toast.error(err))
          .finally(() => setShowDeleteModal(false))
      }

      return (
        <>
          <div className='d-flex justify-content-center flex-shrink-0'>
            <div
              onClick={() => {
                props.data[props.row.index].isEditable && handleEdit()
              }}
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title={
                props.data[props.row.index].isEditable
                  ? 'Edit'
                  : 'Editing this Voucher Type is not allowed'
              }
              style={{opacity: !props.data[props.row.index].isEditable ? 0.65 : 1}}
              className={clsx(
                'btn btn-icon btn-bg-light btn-sm me-1',
                props.data[props.row.index].isEditable && 'btn-active-color-primary'
              )}
            >
              <KTIcon iconName='pencil' className='fs-3' />
            </div>
            <div
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title={
                props.data[props.row.index].isEditable
                  ? 'Delete'
                  : 'Deleting this Voucher Type is not allowed'
              }
              style={{opacity: !props.data[props.row.index].isEditable ? 0.65 : 1}}
              className={clsx(
                'btn btn-icon btn-bg-light btn-sm me-1',
                props.data[props.row.index].isEditable && 'btn-active-color-primary'
              )}
              onClick={() => {
                props.data[props.row.index].isEditable && setShowDeleteModal(true)
              }}
            >
              <KTIcon iconName='trash' className='fs-3' />
            </div>
          </div>
          <Modal
            className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
            id='disable_financial_year'
            role='dialog'
            data-backdrop='false'
            aria-hidden='true'
            tabIndex='-1'
            show={showDeleteModal}
            animation={false}
          >
            <div className='modal-content'>
              {/*begin::Header*/}
              <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
                <h5 className='fw-bold m-0'>Delete Voucher</h5>
                <div className='d-flex ms-2'>
                  {/*begin::Close*/}
                  <div
                    className='btn btn-icon btn-sm btn-light-primary ms-2'
                    data-bs-dismiss='modal'
                    onClick={() => setShowDeleteModal(false)}
                  >
                    <KTIcon className='fs-1' iconName='cross' />
                  </div>
                  {/*end::Close*/}
                </div>
              </div>
              {/*end::Header*/}
              <div className='text-center py-5 px-5'>
                Are you sure you want to delete this Voucher?
              </div>
              <div className='text-center pt-5 pb-5'>
                <button
                  type='reset'
                  onClick={() => setShowDeleteModal(false)}
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Cancel
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={handleDelete}
                >
                  Proceed
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    },
  },
]

export {getVoucherColumns}
