import { Column } from "react-table";
import { ParticularsResponse, RegisterResponse } from "./api"
import { Link, useNavigate } from "react-router-dom";
import { KTIcon } from "../../../_metronic/helpers";

export const getRegisterColumns = (activeTab: string, debouncedFrom: string, debouncedTo: string, view: string): ReadonlyArray<Column<RegisterResponse>> => ([
  {
    Header: () => <th className='px-3 min-w-100px col-lg-1'>Particulars</th>,
    accessor: 'particulars',
    Cell: ({ ...props }) => <div className="px-3">
      <Link to={`particulars`}
        state={{
          activeTab,
          from: debouncedFrom,
          to: debouncedTo,
          view,
          particulars: props.data[props.row.index].particulars
        }}
        className='text-dark fw-bolder text-hover-primary' >
        {props.data[props.row.index].particulars}
      </Link>

    </div>,
  },
  {
    Header: () => <th className='px-3 min-w-100px col-lg-1'>From Date</th>,
    accessor: 'fromDate',
  },
  {
    Header: () => <th className='px-3 min-w-100px col-lg-1'>To Date</th>,
    accessor: 'toDate',
  },
  {
    Header: () => <th className='px-3 min-w-100px col-lg-1'>Credit</th>,
    accessor: 'credit',
  },
  {
    Header: () => <th className='px-3 min-w-100px col-lg-1'>Debit</th>,
    accessor: 'debit',
  },
  {
    Header: () => <th className='px-3 min-w-100px col-lg-1'>Closing Balance</th>,
    accessor: 'closingBalance',
  },
]);

export const getParticularsColumns = (activeTab: string, debouncedFrom: string, debouncedTo: string, view: string): ReadonlyArray<Column<ParticularsResponse>> => ([
  {
    Header: () => <th className='px-3 min-w-100px col-lg-1'>Particulars</th>,
    accessor: 'particulars',
    Cell: ({ ...props }) => <div className="px-3">
      <Link to={`particulars`}
        state={{
          activeTab,
          from: debouncedFrom,
          to: debouncedTo,
          view,
          particulars: props.data[props.row.index].particulars
        }}
        className='text-dark fw-bolder text-hover-primary' >
        {props.data[props.row.index].particulars.parentLedger}
        {props.data[props.row.index].particulars.subLedger && ` - ${props.data[props.row.index].particulars.subLedger}`}
      </Link>

    </div>,
  },
  {
    Header: () => <th className='px-3 min-w-100px col-lg-1'>Voucher Type</th>,
    accessor: 'voucherType',
  },
  {
    Header: () => <th className='px-3 min-w-100px col-lg-1'>Credit</th>,
    accessor: 'credit',
  },
  {
    Header: () => <th className='px-3 min-w-100px col-lg-1'>Debit</th>,
    accessor: 'debit',
  },

  {
    Header: () => <th className='px-3 min-w-100px col-lg-1'>Actions</th>,
    accessor: 'fromDate',
    Cell: (props) => {
      const navigate = useNavigate();

      return (
        <>
          <div className='d-flex justify-content-start flex-shrink-0'>

            <div
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title='View Transactions'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={() => {
                let url = `/transactions/trial-balance/ledger?parent_ledger=${props.data[props.row.index].particulars.parentLedger}`;
                navigate(url);
              }}
            >
              <KTIcon iconName='arrow-right' className='fs-2' />
            </div>
          </div>


        </>)
    },
  },
]);