import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface RegisterResponse {
  particulars: string
  fromDate: string
  toDate: string
  credit: number
  debit: number
  closingBalance: number
}

export const getRegisterValues = async (
  voucher_type: string,
  company_id: string,
  fy_name: string,
  from_date: string,
  to_date: string,
  view: string
): Promise<RegisterResponse[] | undefined> => {
  return await axios
    .get(`${API_URL}/accounting/report/register/filters/${voucher_type}`, {
      params: {
        company_id,
        fy_name,
        from_date,
        to_date,
        view,
      },
    })
    .then((response: AxiosResponse<RegisterResponse[]>) => response.data)
    .then((response: RegisterResponse[]) => response)
}

export interface ParticularsResponse {
  particulars: Particulars
  voucherType: string
  debit: number
  credit: number
  fromDate: string
  toDate: string
}

export interface Particulars {
  parentLedger: string
  subLedger: null
}

export const getParticularsValues = async (
  voucher_type: string,
  company_id: string,
  fy_name: string,
  view: string,
  particulars: string,
  from_date: string,
  to_date: string
): Promise<ParticularsResponse[] | undefined> => {
  return await axios
    .get(`${API_URL}/accounting/report/register/${voucher_type}`, {
      params: {
        company_id,
        fy_name,
        from_date,
        to_date,
        view,
        particulars,
      },
    })
    .then((response: AxiosResponse<ParticularsResponse[]>) => response.data)
    .then((response: ParticularsResponse[]) => response)
}
