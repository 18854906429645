import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface AccountingDetails {
  byLedger: ByLedger
  toLedger: ToLedger
  voucherTypeMap: VoucherTypeMap
  voucherCurrency: string[]
  ledgerCurrentBalanceMapping: Record<string, Record<string, string>>
  reportingCurrency: string
}

export interface ByLedger {
  journal: Record<string, string[]>
  receipt: Record<string, string[]>
  payment: Record<string, string[]>
  contra: Record<string, string[]>
  sales: Record<string, string[]>
  purchases: Record<string, string[]>
  debitnote: Record<string, string[]>
  creditnote: Record<string, string[]>
}

export interface ToLedger {
  journal: Record<string, string[]>
  receipt: Record<string, string[]>
  payment: Record<string, string[]>
  contra: Record<string, string[]>
  sales: Record<string, string[]>
  purchases: Record<string, string[]>
  debitnote: Record<string, string[]>
  creditnote: Record<string, string[]>
}

export interface VoucherTypeMap {
  journal: string[]
  receipt: string[]
  payment: string[]
  contra: string[]
  sales: string[]
  purchase: string[]
  debitnote: string[]
  creditnote: string[]
}

export const getAccountingDetails = async (
  companyId: string,
  fy: string
): Promise<AccountingDetails | undefined> => {
  return await axios
    .get(`${API_URL}/accounting/transactions/data/${companyId}/${fy}`)
    .then((response: AxiosResponse<AccountingDetails>) => response.data)
    .then((response: AccountingDetails) => response)
}

export type IndividualTransaction = {
  transactionType: 'debit' | 'credit'
  parentLedger: string
  subLedger: string | null
  ledgerCode?: string | null
  amount: number
  transactionAmount?: number
}

export interface CreateTransactionBody {
  dbId?: number
  companyId?: string
  fyName?: string
  voucherType?: string
  voucherName?: string | null
  voucherDate?: string
  voucherCurrency?: string
  reportingCurrency?: string
  transactingCurrency?: string
  transactionDetails?: IndividualTransaction[]
  narration?: string
  previousVoucherType?: string
  fetchedRate?: number
  applicableRate?: number
  updateExchangeRate?: boolean
  voucherNumber?: number
  totalAmount?: number
  invoiceNumber?: string
  invoiceAmount?: number
  invoiceDescription?: string
  invoiceDetails?: SetOffInvoiceResp[]
  exchangeMultiplier?: string
}

export const createTransaction = async (
  body: CreateTransactionBody
): Promise<{detail: string} | undefined> => {
  try {
    const response = await axios.post(`${API_URL}/accounting/transactions/save`, body)
    return response.data
  } catch (error: any) {
    if (error.response) {
      throw error.response.data
    }
  }
}

type ExchangeRateResp = {
  currencyCode: string
  exchangeRate: number
  asOnDate: string
}

export const getExchangeRate = async (
  companyId: string,
  fy: string,
  ref_date: string,
  currency_code: string
): Promise<ExchangeRateResp | undefined> => {
  return await axios
    .get(
      `${API_URL}/accounting/transactions/data/exchange-rate/${companyId}/${fy}?ref_date=${ref_date}&currency_code=${currency_code}`
    )
    .then((response: AxiosResponse<ExchangeRateResp>) => response.data)
    .then((response: ExchangeRateResp) => response)
}

export interface SetOffInvoiceResp {
  invoiceNumber: string
  invoiceAmount: number
  settledInvoiceAmount: number
  setOffAmount: number
  voucherType: string
  voucherNumber: number
}

export const getSetOffInvoiceDetails = async (
  companyId: string,
  fy: string,
  parentLedger: string,
  voucher_date: string,
  subLedger?: string
): Promise<{invoiceDetails: SetOffInvoiceResp[]} | undefined> => {
  let url = `${API_URL}/accounting/transactions/invoice/details/${companyId}/${fy}?parent_ledger=${parentLedger}&voucher_date=${voucher_date}`
  if (subLedger) url += `&sub_ledger=${subLedger}`
  url = encodeURI(url)
  return await axios
    .get(url)
    .then((response: AxiosResponse<{invoiceDetails: SetOffInvoiceResp[]}>) => response.data)
    .then((response: {invoiceDetails: SetOffInvoiceResp[]}) => response)
}
export const createNewTransaction = async (body: CreateTransactionBody) => {
  try {
    const response = await axios.post(`${API_URL}/accounting/transactions/create-reference`, body)
    return response.data
  } catch (error: any) {
    if (error.response) {
      throw error.response.data
    }
  }
}
