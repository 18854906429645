// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from '../../apps/user-management/users-list/table/columns/UserCustomHeader'
import {Currency, deleteCurrency} from './api'
import {KTIcon} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import {toast} from 'react-hot-toast'
import {useState} from 'react'

const getCurrencyColumns = (
  setCreateCurrency,
  setEditCurrency
): ReadonlyArray<Column<Currency>> => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Currency Code'
        className='min-w-125px ps-4 text-center'
      />
    ),
    accessor: 'currencyCode',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Formal Name'
        className='min-w-125px text-center'
      />
    ),
    accessor: 'formalName',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Currency Symbol'
        className='min-w-125px ps-4 text-center'
      />
    ),
    accessor: 'currencySymbol',
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Exchange Date' className='min-w-125px' />,
  //   accessor: 'exchangeDate',
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Exchange Rate' className='min-w-125px ps-4' />,
  //   accessor: 'exchangeRate',
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='min-w-125px text-center' />
    ),
    accessor: 'actions',
    Cell: ({...props}) => {
      const [showDeleteModal, setShowDeleteModal] = useState(false)

      // const handleEdit = () => {
      //   setCreateCurrency(true);
      //   setEditCurrency(props.data[props.row.index]);
      // };

      const handleDelete = async () => {
        deleteCurrency(props.data[props.row.index].currencyCode)
          .then((res) => res?.detail && toast.success(res.detail))
          .catch((err) => err && toast.error(err))
          .finally(() => setShowDeleteModal(false))
      }

      return (
        <>
          <div className='d-flex justify-content-center flex-shrink-0'>
            {/* <div onClick={handleEdit}
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='Edit'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTIcon iconName='pencil' className='fs-3' />
          </div> */}
            <div
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title='Delete'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={() => setShowDeleteModal(true)}
            >
              <KTIcon iconName='trash' className='fs-3' />
            </div>
          </div>
          <Modal
            className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
            id='disable_financial_year'
            role='dialog'
            data-backdrop='false'
            aria-hidden='true'
            tabIndex='-1'
            show={showDeleteModal}
            animation={false}
          >
            <div className='modal-content'>
              {/*begin::Header*/}
              <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
                <h5 className='fw-bold m-0'>Delete Currency</h5>
                <div className='d-flex ms-2'>
                  {/*begin::Close*/}
                  <div
                    className='btn btn-icon btn-sm btn-light-primary ms-2'
                    data-bs-dismiss='modal'
                    onClick={() => setShowDeleteModal(false)}
                  >
                    <KTIcon className='fs-1' iconName='cross' />
                  </div>
                  {/*end::Close*/}
                </div>
              </div>
              {/*end::Header*/}
              <div className='text-center py-5 px-5'>
                Are you sure you want to delete this Currency?
              </div>
              <div className='text-center pt-5 pb-5'>
                <button
                  type='reset'
                  onClick={() => setShowDeleteModal(false)}
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Cancel
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={handleDelete}
                >
                  Proceed
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    },
  },
]

export {getCurrencyColumns}
