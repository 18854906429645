import {KTCard, KTIcon, KTCardBody, useDebounce} from '../../../../_metronic/helpers'
import {toast} from 'react-hot-toast'
import {
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {useEffect, useMemo, useState, Fragment} from 'react'
import {getTrialBalanceColumns} from './Columns'
import {downloadTrialBalance, getTrialBalanceData} from '../API/TrialBalanceAPI'
import {Loader} from '../../product-master/loader'
import {useLayout} from '../../../../_metronic/layout/core'

interface TrialBalanceRow {
  voucherType?: string
  group?: string
  subGroup?: string
  ledger?: string
  credit: string | null
  debit: string | null
  children?: TrialBalanceRow[]
}

export const TrialBalance: React.FC = () => {
  const {company, financialYear} = useLayout()

  const [totals, setTotals] = useState({
    differenceAmount: '',
    differencePercentage: 0,
    totalDebit: '',
    totalCredit: '',
  })
  const [data, setData] = useState<TrialBalanceRow[]>([])

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [from, setFrom] = useState<string>('')
  const [to, setTo] = useState<string>('')
  const [isDateUpdate, setIsDateUpdate] = useState(false)

  useEffect(() => {
    const [year1, year2] = financialYear?.value.split('-')
    setFrom(new Date(Number(year1.trim()), 3, 2).toISOString().split('T')[0])
    setTo(new Date(Number(year2.trim()), 3, 1).toISOString().split('T')[0])
    setIsDateUpdate(true)
  }, [financialYear])

  // const debouncedFrom = useDebounce(from, 3000)
  // const debouncedTo = useDebounce(to, 3000)

  const columns = useMemo(() => getTrialBalanceColumns(totals, from, to), [totals, from, to])

  const [expanded, setExpanded] = useState<ExpandedState>({
    '0': true,
    '1': true,
    '2': true,
    '3': true,
  })

  const table = useReactTable({
    columns,
    data,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.children,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
  })

  useEffect(() => {
    const getData = async () => {
      if (company?.value && financialYear?.value && from && to && isDateUpdate) {
        setIsError(false)
        setIsLoading(true)
        await getTrialBalanceData(company.value, financialYear.value, from, to)
          .then((res) => {
            const apiData = []

            // Assets
            let children = !!res?.Assets
              ? [
                  {...res?.Assets?.['Non-Current Assets'], group: 'Non-Current Assets'},
                  {...res?.Assets?.['Current Assets'], group: 'Current Assets'},
                ]
              : undefined
            let balance = {
              voucherType: 'Assets',
              group: '',
              subGroup: '',
              ledger: '',
              credit: null,
              debit: null,
              isExpanded: true,
              children,
            }
            apiData.push(balance)

            // Liabilities
            children = !!res?.Liabilities
              ? [
                  {...res?.Liabilities?.['Equity'], group: 'Equity'},
                  {...res?.Liabilities?.['Current Liabilities'], group: 'Current Liabilities'},
                  {
                    ...res?.Liabilities?.['Non-Current Liabilities'],
                    group: 'Non-Current Liabilities',
                  },
                ]
              : undefined
            balance = {
              voucherType: 'Liabilities',
              group: '',
              subGroup: '',
              ledger: '',
              credit: null,
              debit: null,
              isExpanded: true,
              children,
            }
            apiData.push(balance)

            // Income
            children = !!res?.Incomes
              ? [
                  {...res?.Incomes?.['Direct Income'], group: 'Direct Income'},
                  {...res?.Incomes?.['Indirect Income'], group: 'Indirect Income'},
                ]
              : undefined
            balance = {
              voucherType: 'Income',
              group: '',
              subGroup: '',
              ledger: '',
              credit: null,
              debit: null,
              isExpanded: true,
              children,
            }
            apiData.push(balance)

            // Expense
            children = !!res?.Expenses
              ? [
                  {...res?.Expenses?.['Direct Expenses'], group: 'Direct Expenses'},
                  {...res?.Expenses?.['Indirect Expenses'], group: 'Indirect Expenses'},
                ]
              : undefined
            balance = {
              voucherType: 'Expense',
              group: '',
              subGroup: '',
              ledger: '',
              credit: null,
              debit: null,
              isExpanded: true,
              children,
            }
            apiData.push(balance)

            setData(apiData)
            res &&
              setTotals({
                differenceAmount: res?.differenceAmount,
                totalCredit: res?.totalCredit,
                totalDebit: res?.totalDebit,
                differencePercentage: res?.differencePercentage,
              })
          })
          .catch((err) => {
            console.log(err)
            err && toast.error(err.detail || 'Something went wrong. Please try again later.')
            setIsError(true)
          })
          .finally(() => {
            setIsLoading(false)
            setIsDateUpdate(false)
          })
      }
    }
    getData()
  }, [company?.value, financialYear?.value, isDateUpdate])

  const handleDownload = async () => {
    toast.success('Download has been started successfully')
    const res = await downloadTrialBalance({
      company_id: company.value,
      fy_name: financialYear.value,
      from_date: from,
      to_date: to,
    })
    if (res) {
      console.log(res)
      // Convert the XLSX workbook back to a Blob
      const excelBlob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      // Create a download URL for the Blob
      const url = window.URL.createObjectURL(excelBlob)

      const a = document.createElement('a')
      a.href = url
      a.download = `Trial-Balance-${company.label}-${financialYear.value}.xlsx`
      document.body.appendChild(a)
      a.click()
    }
  }

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              {/* <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' /> */}
              <input
                type='date'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14 me-3'
                placeholder='Search'
                value={from}
                onChange={(e) => setFrom(e.target.value)}
              />
              <input
                type='date'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14 mx-3'
                placeholder='Search'
                value={to}
                onChange={(e) => setTo(e.target.value)}
              />
              <button
                type='button'
                className='btn btn-primary btn-lg ms-3'
                style={{
                  height: '43.59px',
                  width: '43.59px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 0,
                }}
                onClick={() => {
                  if (!from || !to)
                    toast.error('Please make sure both the From and To dates are selected')
                  else setIsDateUpdate(true)
                }}
              >
                <KTIcon iconName='arrows-loop' className='fs-1 px-0' />
              </button>
            </div>
            {/* end::Search */}
          </div>
          {/* begin::Card toolbar */}
          <div className='card-toolbar'>
            {/* begin::Group actions */}
            {
              <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                {/* begin::Add user */}
                <button
                  disabled={!data.length}
                  type='button'
                  className='btn btn-primary'
                  onClick={handleDownload}
                >
                  <KTIcon iconName='arrow-down' className='fs-2' />
                  Download Excel
                </button>
                {/* end::Add user */}
              </div>
            }
            {/* end::Group actions */}
          </div>
          {/* end::Card toolbar */}
        </div>
        <KTCardBody className={`py-4 ${from && to && isDateUpdate ? 'position-relative' : ''}`}>
          {!isLoading && (
            <div className='table-responsive'>
              <table className='table align-middle fs-6  dataTable '>
                {data.length > 0 ? (
                  <>
                    <thead>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr
                          key={headerGroup.id}
                          className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'
                        >
                          {headerGroup.headers.map((header) => (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              style={{
                                border: '1px solid #e1e3ea',
                              }}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody className='text-gray-600 fw-bold'>
                      {table.getRowModel().rows.map((row, i) => (
                        <Fragment key={i}>
                          <tr
                            key={row.id}
                            onClick={
                              row.depth > 0 || row.getCanExpand()
                                ? row.getToggleExpandedHandler()
                                : undefined
                            }
                            className='bg-hover-light'
                          >
                            {row.getVisibleCells().length > 0 ? (
                              row
                                .getVisibleCells()
                                .map((cell) => (
                                  <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </td>
                                ))
                            ) : (
                              <tr>
                                <td colSpan={7}>
                                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    No matching records found
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tr>
                        </Fragment>
                      ))}
                    </tbody>
                    <tfoot>
                      {table.getFooterGroups().map((footerGroup) => (
                        <tr key={footerGroup.id}>
                          {footerGroup.headers.map((header) => (
                            <th key={header.id}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.footer, header.getContext())}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </tfoot>
                  </>
                ) : (
                  <thead>
                    <tr className='d-flex text-center w-100 align-content-center justify-content-center'>
                      <th>No matching records found</th>
                    </tr>
                  </thead>
                )}
              </table>
            </div>
          )}
          {isLoading && <Loader isError={isError} />}
        </KTCardBody>
      </KTCard>
    </>
  )
}

const styles = {
  borderRadius: '0.475rem',
  boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
  backgroundColor: '#fff',
  color: '#7e8299',
  fontWeight: '500',
  margin: '0',
  width: 'auto',
  padding: '1rem 2rem',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 4rem)',
}
