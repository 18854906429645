import {ExpandedState, Updater, createColumnHelper} from '@tanstack/react-table'
import {CardsWidget7} from '../../../../_metronic/partials/widgets'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import SVG from 'react-inlinesvg'

interface TrialBalance {
  voucherType?: string
  group?: string
  subGroup?: string
  ledger?: string
  credit: string | null
  debit: string | null
  children?: TrialBalance[]
}

const columnHelper = createColumnHelper<TrialBalance>()

const getTrialBalanceColumns = (
  totals: {
    differenceAmount: string
    totalDebit: string
    totalCredit: string
  },
  from?: string,
  to?: string
) => [
  columnHelper.accessor('voucherType', {
    header: () => <span className='px-3'>Classification</span>,
    cell: ({row, getValue}) => {
      return (
        getValue() && (
          <div className='d-flex align-items-center max-w-125px px-3 fw-bolder text-primary fs-2'>
            {getValue()}
            {row.depth === 0 ? (
              <span
                className='text-muted'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <SVG
                  src={toAbsoluteUrl(
                    `/media/icons/duotune/arrows/arr08${row.getIsExpanded() ? '1' : '2'}.svg`
                  )}
                />
              </span>
            ) : (
              <></>
            )}
          </div>
        )
      )
    },
  }),
  columnHelper.accessor('group', {
    header: () => <span className='min-w-125px'>Group</span>,
    cell: ({row, getValue}) => {
      return (
        <div className='min-w-125px d-flex align-items-start'>
          {row.original.children && row.original.children.length > 0 ? (
            <>
              {row.getCanExpand() && row.original.children && (
                <>
                  {row.depth === 1 && (
                    <span
                      {...{
                        onClick: (e) => {
                          e.stopPropagation()
                          row.toggleExpanded()
                        },
                        style: {cursor: 'pointer'},
                        className: 'align-self-end',
                      }}
                    >
                      {row.getIsExpanded() ? (
                        <KTIcon iconName='up' className='text-dark fs-2' />
                      ) : (
                        <KTIcon iconName='down' className='text-dark fs-2' />
                      )}
                    </span>
                  )}
                </>
              )}
            </>
          ) : (
            <span className='ms-6'></span>
          )}

          <a
            href={`/transactions/trial-balance/group?parent_group=${getValue()}&from=${from}&to=${to}`}
            className='text-dark fw-bolder text-hover-primary'
          >
            {getValue()}
          </a>
        </div>
      )
    },
  }),
  columnHelper.accessor('subGroup', {
    header: () => <span className='min-w-125px'>Sub Group</span>,
    cell: ({row, getValue}) => {
      return (
        <div className='min-w-125px d-flex align-items-start'>
          {row.original.children && row.original.children.length > 0 ? (
            <>
              {row.getCanExpand() && row.original.children && (
                <>
                  {row.depth === 2 && (
                    <span
                      {...{
                        onClick: (e) => {
                          e.stopPropagation()
                          row.toggleExpanded()
                        },
                        style: {cursor: 'pointer'},
                        className: 'align-self-end',
                      }}
                    >
                      {row.getIsExpanded() ? (
                        <KTIcon iconName='up' className='text-dark fs-2' />
                      ) : (
                        <KTIcon iconName='down' className='text-dark fs-2' />
                      )}
                    </span>
                  )}
                </>
              )}
            </>
          ) : (
            <span className='ms-5'></span>
          )}
          <a
            href={`/transactions/trial-balance/group?parent_group=${
              row.getParentRow()?.original.group
            }&sub_group=${getValue()}&from=${from}&to=${to}`}
            className='text-dark fw-semibold text-hover-primary'
          >
            {getValue()}
          </a>
        </div>
      )
    },
  }),
  columnHelper.accessor('ledger', {
    header: () => <span className='min-w-125px'>Ledger</span>,
    cell: ({row, getValue}) => {
      const parentRow = row.getParentRow()
      let url = `/transactions/trial-balance/ledger?parent_group=${
        parentRow?.getParentRow()?.original.group
      }&sub_group=${parentRow?.original.subGroup}&from=${from}&to=${to}`
      const ledger = getValue()
      if (ledger && ledger.includes('-')) {
        const [parent_ledger, sub_ledger] = ledger.split('-')
        url += `&parent_ledger=${parent_ledger.trim()}&sub_ledger=${sub_ledger.trim()}`
      } else url += `&parent_ledger=${ledger}`
      return (
        <a href={url} className='text-muted text-hover-primary'>
          <em>{getValue()}</em>
        </a>
      )
    },
    footer: () => (
      <CardsWidget7
        className='border border-dashed border-gray-300'
        stats={totals.differenceAmount}
        description='Difference'
      />
    ),
  }),
  columnHelper.accessor('debit', {
    header: () => <span className='min-w-125px'>Debit</span>,
    cell: ({row, table}) => (
      <>
        {row.depth > 0 ? (
          <div
            className={
              row.depth === 1
                ? 'text-dark fw-bold'
                : row.depth === 2
                ? 'text-dark fw-semibold'
                : 'text-muted ki-text-italic'
            }
            style={{textAlign: 'right', width: '100%'}} // Added text-align: right;
          >
            {row.original.debit ?? '-'}
          </div>
        ) : (
          <span
            // onClick={() => table.setExpanded({'0': true, '1': true, '2': true, '3': true})}
            style={{cursor: 'pointer', textAlign: 'right', width: '100%', display: 'block'}}
          >
            {/* <i className='bi bi-dash-circle-fill fs-1 text-dark' /> */}
          </span>
        )}
      </>
    ),
    footer: () => (
      <CardsWidget7
        className='border border-dashed border-gray-300'
        stats={totals.totalDebit}
        description='Total Debits'
      />
    ),
  }),
  columnHelper.accessor('credit', {
    header: () => <span className='min-w-125px'>Credit</span>,
    cell: ({row, table}) => (
      <>
        {row.depth > 0 ? (
          <div
            className={
              row.depth === 1
                ? 'text-dark fw-bold'
                : row.depth === 2
                ? 'text-dark fw-semibold'
                : 'text-muted'
            }
            style={{textAlign: 'right'}}
          >
            {row.original.credit ? row.original.credit : '-'}
          </div>
        ) : (
          <span
            onClick={() => {
              const exp: Updater<ExpandedState> = table.getState().expanded as Record<
                string,
                boolean
              >
              row.subRows.map((s) => {
                exp[s.id] = true

                s.subRows.map((s1) => {
                  exp[s1.id] = true

                  s1.subRows.map((s2) => {
                    exp[s2.id] = true
                    return null
                  })
                  return null
                })

                return null
              })
              exp[row.id] = false
              table.setExpanded(exp)
              row.toggleExpanded()
            }}
            style={{cursor: 'pointer', textAlign: 'right', width: '100%', display: 'block'}}
          >
            {/* <i className='bi bi-plus-circle-fill fs-1 text-dark' /> */}
          </span>
        )}
      </>
    ),
    footer: () => (
      <CardsWidget7
        className='border border-dashed border-gray-300'
        stats={totals.totalCredit}
        description='Total Credits'
      />
    ),
  }),
]

export {getTrialBalanceColumns}
