import React, {useState} from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {HeaderTabs} from './HeaderTabs'
import Sales from './Sales'
import Purchase from './Purchase'
import Expense from './Expense'

export interface VoucherTypeMap {
  sales: string[]
  purchase: string[]
  expense: string[]
}
const UploadRecords: React.FC = () => {
  const [activeTab, setActiveTab]: any = useState<keyof VoucherTypeMap>('sales')

  return (
    <Routes>
      <Route
        element={
          <>
            <HeaderTabs setActiveTab={setActiveTab} />
            <Outlet />
          </>
        }
      >
        <Route path='sales' element={<Sales />} />
        <Route path='purchase' element={<Purchase />} />
        <Route path='expense' element={<Expense />} />
        <Route index element={<Navigate to='/uploadRecords/sales' />} />
      </Route>
    </Routes>
  )
}

export default UploadRecords
