// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from '../../apps/user-management/users-list/table/columns/UserCustomHeader'
import {ProductGroup, deleteProductGroup} from './api'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import {toast} from 'react-hot-toast'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'

const getGroupColumns = (setEditGroup, setCreateGroup): ReadonlyArray<Column<ProductGroup>> => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Classification'
        className='min-w-125px ps-4 text-center'
      />
    ),
    accessor: 'classification',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Parent Group'
        className='min-w-125px text-center'
      />
    ),
    accessor: 'parentGroup',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Sub Group' className='min-w-125px text-center' />
    ),
    accessor: 'subGroup',
    Cell: ({...props}) => (
      <div>
        {props.data[props.row.index].subGroup === null ? '-' : props.data[props.row.index].subGroup}{' '}
      </div>
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Is Parent Group'
        className='min-w-125px text-center'
      />
    ),
    accessor: 'isParentGroup',
    Cell: ({...props}) => <div>{props.data[props.row.index].isParentGroup ? 'Yes' : 'No'} </div>,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='min-w-125px text-center' />
    ),
    accessor: 'actions',
    Cell: (props) => {
      const navigate = useNavigate()

      const [showDeleteModal, setShowDeleteModal] = useState(false)

      const handleEdit = () => {
        if (props.data[props.row.index].isEditable) {
          setCreateGroup(true)
          setEditGroup(props.data[props.row.index])
        }
      }

      const handleDelete = async () => {
        deleteProductGroup(props.data[props.row.index].dbId)
          .then((res) => res?.detail && toast.success(res.detail))
          .catch((err) => err && toast.error(err))
          .finally(() => setShowDeleteModal(false))
      }

      return (
        <>
          <div className='d-flex justify-content-center flex-shrink-0'>
            <div
              onClick={props.data[props.row.index].isEditable ? handleEdit : undefined}
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title={
                props.data[props.row.index].isEditable
                  ? 'Edit'
                  : 'Editing this Group is not allowed'
              }
              style={{opacity: !props.data[props.row.index].isEditable ? 0.65 : 1}}
              className={clsx(
                'btn btn-icon btn-bg-light btn-sm me-1',
                props.data[props.row.index].isEditable && 'btn-active-color-primary'
              )}
            >
              <KTIcon
                iconName='pencil'
                className={clsx('fs-3', !props.data[props.row.index].isEditable && 'disabled')}
              />
            </div>
            <div
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title={
                props.data[props.row.index].isEditable
                  ? 'Delete'
                  : 'Deleting this group is not allowed'
              }
              style={{opacity: !props.data[props.row.index].isEditable ? 0.65 : 1}}
              className={clsx(
                'btn btn-icon btn-bg-light btn-sm me-1',
                props.data[props.row.index].isEditable && 'btn-active-color-primary'
              )}
              onClick={() =>
                props.data[props.row.index].isEditable ? setShowDeleteModal(true) : undefined
              }
            >
              <KTIcon
                iconName='trash'
                className={clsx('fs-3', !props.data[props.row.index].isEditable && 'disabled')}
              />
            </div>
            <div
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title='View Transactions'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={() => {
                let url = `/transactions/trial-balance/group?parent_group=${
                  props.data[props.row.index].parentGroup
                }`
                !props.data[props.row.index].isParentGroup &&
                  (url = url + `&sub_group=${props.data[props.row.index].subGroup}`)
                navigate(url)
              }}
            >
              <KTIcon iconName='arrow-right' className='fs-2' />
            </div>
          </div>

          <Modal
            className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
            id='disable_financial_year'
            role='dialog'
            data-backdrop='false'
            aria-hidden='true'
            tabIndex='-1'
            show={showDeleteModal}
            animation={false}
          >
            <div className='modal-content'>
              {/*begin::Header*/}
              <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
                <h5 className='fw-bold m-0'>Delete Group</h5>
                <div className='d-flex ms-2'>
                  {/*begin::Close*/}
                  <div
                    className='btn btn-icon btn-sm btn-light-primary ms-2'
                    data-bs-dismiss='modal'
                    onClick={() => setShowDeleteModal(false)}
                  >
                    <KTIcon className='fs-1' iconName='cross' />
                  </div>
                  {/*end::Close*/}
                </div>
              </div>
              {/*end::Header*/}
              <div className='text-center py-5 px-5'>
                Are you sure you want to delete this Group? Please note the Group can only be
                deleted if there are no transactions under this Group.
              </div>
              <div className='text-center pt-5 pb-5'>
                <button
                  type='reset'
                  onClick={() => setShowDeleteModal(false)}
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Cancel
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={handleDelete}
                >
                  Proceed
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    },
  },
]

export {getGroupColumns}
