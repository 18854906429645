import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface TrialBalanceResp {
  Expenses: {
    'Direct Expenses': BalanceDetails
    'Indirect Expenses': BalanceDetails
  }
  Assets: {
    'Non-Current Assets': BalanceDetails
    'Current Assets': BalanceDetails
  }
  Liabilities: {
    'Non-Current Liabilities': BalanceDetails
    'Current Liabilities': BalanceDetails
    Equity: BalanceDetails
  }
  Incomes: {
    'Direct Income': BalanceDetails
    'Indirect Income': BalanceDetails
  }
  differenceAmount: string
  totalDebit: string
  totalCredit: string
}

export interface BalanceDetails {
  group?: string
  subGroup?: string
  ledger?: string
  debit: string | null
  credit: string | null
  children?: BalanceDetails[]
}

export const getTrialBalanceData = async (
  company_id: string,
  fy_name: string,
  from_date: string,
  to_date: string
): Promise<TrialBalanceResp | undefined> => {
  try {
    const res = await axios.get(`${API_URL}/accounting/report/trial-balance`, {
      params: {
        company_id,
        fy_name,
        from_date,
        to_date,
      },
    })
    return res.data
  } catch (error: any) {
    if (error.response) throw error.response.data
  }
}
