import clsx from 'clsx'
import {ProductGroup} from './api'
import {ColumnInstance, Row} from 'react-table'

type ColProps = {
  column: ColumnInstance<ProductGroup>
}

export const CustomHeaderColumn: React.FC<ColProps> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

type RowProps = {
  row: Row<ProductGroup>
}

export const CustomRow: React.FC<RowProps> = ({row}) => (
  <tr {...row.getRowProps()} className='bg-hover-light'>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
        >
          {cell.render('Cell')}
        </td>
      )
    })}
  </tr>
)

export const styles = {
  borderRadius: '0.475rem',
  boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
  backgroundColor: '#fff',
  color: '#7e8299',
  fontWeight: '500',
  margin: '0',
  width: 'auto',
  padding: '1rem 2rem',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 4rem)',
}
