import React, {useState} from 'react'
import {KTCard, KTCardBody} from '../../../_metronic/helpers'
import {CustomRow} from '../register'

export default function Purchase() {
  const [rows, setRows]: any = useState([])
  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='view_transactions_table'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead>
                <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                  <th>col 1</th>
                  <th>col 2</th>
                  <th>col 3</th>
                  <th>col 4</th>
                </tr>
              </thead>
              <tbody
                className='text-gray-600 fw-bold'
                // {...getTableBodyProps()}
              >
                {rows?.length > 0 ? (
                  rows.map((row: any, i: any) => {
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* {isLoading && (
            <div style={{...styles, position: 'absolute', textAlign: 'center'}}>Processing...</div>
          )} */}
        </KTCardBody>
      </KTCard>
    </>
  )
}
