import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface PLReportResp {
  grossProfit: string | null
  grossLoss: string | null
  netProfit: string | null
  netLoss: string | null
  'Direct Expenses': DirectExpenses
  'Indirect Expenses': DirectExpenses
  'Direct Income': DirectExpenses
  'Indirect Income': DirectExpenses
}

export interface DirectExpenses {
  data: Datum[]
  amount: string
}

export interface Datum {
  parentLedger: string
  subLedger: null | string
  amount: string
}

export interface PLReportParams {
  company_id: string
  fy_name: string
  from_date?: string
  to_date?: string
}

export const getPLReport = async (params: PLReportParams): Promise<PLReportResp | undefined> => {
  return await axios
    .get(`${API_URL}/accounting/report/pl-report`, {
      params,
    })
    .then((response: AxiosResponse<PLReportResp>) => response.data)
    .then((response: PLReportResp) => response)
}
