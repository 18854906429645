import {useEffect, useMemo} from 'react'
import Select from 'react-select'
import {KTIcon, formatNumber} from '../../../../_metronic/helpers'
import {IndividualTransaction} from '../API/EnterTransactionsAPI'

type OptionType = {
  value: string | null
  label: string
}

interface TransactionRowProps {
  data: IndividualTransaction[]
  setData: React.Dispatch<React.SetStateAction<IndividualTransaction[]>>
  byLedgerDetails: Record<string, string[]> | undefined
  toLedgerDetails: Record<string, string[]> | undefined
  index: number
  row: IndividualTransaction
  ledgerCurrentBalanceMapping: Record<string, Record<string, string>> | undefined
  showConversion: boolean
  applicableRate: number
  reportingCurrency: string
}

export const TransactionRow: React.FC<TransactionRowProps> = ({
  data,
  setData,
  byLedgerDetails,
  toLedgerDetails,
  applicableRate,
  index,
  row,
  ledgerCurrentBalanceMapping,
  showConversion,
  reportingCurrency,
}) => {
  const {byLedgerOptions} = useMemo(() => {
    let byLedgerOptions: {
      value: string
      label: string
    }[] = []
    if (byLedgerDetails)
      byLedgerOptions = Object.keys(byLedgerDetails)?.map((opt) => ({label: opt, value: opt}))

    return {byLedgerOptions}
  }, [byLedgerDetails])

  const {bySubLedgerOptions} = useMemo(() => {
    let bySubLedgerOptions: OptionType[] = []
    if (byLedgerDetails) {
      byLedgerDetails?.[row?.parentLedger]?.length &&
        (bySubLedgerOptions = byLedgerDetails?.[row?.parentLedger]?.map((opt) => ({
          label: opt,
          value: opt === 'None' ? null : opt,
        })))
    }
    return {bySubLedgerOptions}
  }, [byLedgerDetails, row.parentLedger])

  const {toLedgerOptions} = useMemo(() => {
    let toLedgerOptions: {
      value: string
      label: string
    }[] = []
    if (toLedgerDetails)
      toLedgerOptions = Object.keys(toLedgerDetails)?.map((opt) => ({label: opt, value: opt}))

    return {toLedgerOptions}
  }, [toLedgerDetails])

  const {toSubLedgerOptions} = useMemo(() => {
    let toSubLedgerOptions: OptionType[] = []
    if (toLedgerDetails) {
      toLedgerDetails?.[row?.parentLedger]?.length &&
        (toSubLedgerOptions = toLedgerDetails?.[row?.parentLedger]?.map((opt) => ({
          label: opt,
          value: opt === 'None' ? null : opt,
        })))
    }
    return {toSubLedgerOptions}
  }, [toLedgerDetails, row.parentLedger])

  function handleNumberFieldOnMouseScroll(e: any) {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  useEffect(() => {
    if (byLedgerDetails) {
      const byLedgers = Object.keys(byLedgerDetails)?.map((opt) => opt)
      setData((prev) =>
        prev.map((d) => ({
          ...d,
          parentLedger:
            d.transactionType === 'credit' || byLedgers.includes(d.parentLedger)
              ? d.parentLedger
              : '',
          subLedger:
            d.transactionType === 'credit' || byLedgers.includes(d.parentLedger) ? d.subLedger : '',
        }))
      )
    }
  }, [byLedgerDetails, setData])

  useEffect(() => {
    if (toLedgerDetails) {
      const toLedgers = Object.keys(toLedgerDetails)?.map((opt) => opt)
      setData((prev) =>
        prev.map((d) => ({
          ...d,
          parentLedger:
            d.transactionType === 'debit' || toLedgers.includes(d.parentLedger)
              ? d.parentLedger
              : '',
          subLedger:
            d.transactionType === 'debit' || toLedgers.includes(d.parentLedger) ? d.subLedger : '',
        }))
      )
    }
  }, [toLedgerDetails, setData])

  return (
    <div className='row mb-6 d-flex flex-start'>
      <div className='col-lg-2 d-flex flex-center'>
        <button
          type='button'
          className={`btn btn-sm me-3 ${
            row.transactionType === 'debit' ? 'btn-success' : 'bg-light-success text-success'
          }`}
          style={{height: '38px'}}
          onClick={() => {
            const dat = [...data]
            dat[index].transactionType = 'debit'
            setData(dat)
          }}
        >
          <KTIcon iconName='arrow-right' className='fs-2' />
          By
        </button>

        <button
          type='button'
          className={`btn btn-sm ${
            row.transactionType === 'credit' ? 'btn-danger' : 'bg-light-danger text-danger'
          }`}
          style={{height: '38px'}}
          onClick={() => {
            const dat = [...data]
            dat[index].transactionType = 'credit'
            setData(dat)
          }}
        >
          <KTIcon iconName='arrow-left' className='fs-2' />
          To
        </button>
      </div>
      <div className='col-lg-5'>
        <div className='d-flex flex-row gap-5'>
          <div className='col-lg-5'>
            <Select
              value={row.parentLedger ? {label: row.parentLedger, value: row.parentLedger} : null}
              onChange={(option) => {
                if (option?.value) {
                  const dat = [...data]
                  dat[index].parentLedger = option?.value
                  dat[index].subLedger =
                    toLedgerDetails?.[row?.parentLedger]?.[0] === 'None'
                      ? null
                      : toLedgerDetails?.[row?.parentLedger]?.[0]
                      ? toLedgerDetails?.[row?.parentLedger]?.[0]
                      : null
                  setData(dat)
                }
              }}
              styles={{
                singleValue: (styles) => ({
                  ...styles,
                  color: 'black',
                  fontWeight: 600,
                }),
                placeholder: (styles) => ({
                  ...styles,
                  color: 'black',
                }),
              }}
              options={row.transactionType === 'debit' ? byLedgerOptions : toLedgerOptions}
              isSearchable={true}
              components={{IndicatorSeparator: () => null}}
              placeholder='Choose a Ledger (*)'
            />
          </div>

          <div className='col-lg-5'>
            <Select
              value={
                row.parentLedger
                  ? {label: row.subLedger ? row.subLedger : 'None', value: row.subLedger}
                  : undefined
              }
              onChange={(option) => {
                if (option?.value) {
                  const dat = [...data]
                  dat[index].subLedger = option?.value
                  setData(dat)
                }
              }}
              styles={{
                singleValue: (styles) => ({
                  ...styles,
                  color: 'black',
                  fontWeight: 600,
                }),
                placeholder: (styles) => ({
                  ...styles,
                  color: 'black',
                }),
              }}
              options={row.transactionType === 'debit' ? bySubLedgerOptions : toSubLedgerOptions}
              isSearchable={true}
              components={{IndicatorSeparator: () => null}}
              placeholder='Choose a Sub Ledger'
              isDisabled={!row.parentLedger}
            />
            <div className='form-text'>
              {row.subLedger && reportingCurrency}{' '}
              {ledgerCurrentBalanceMapping?.[row.parentLedger]?.[row.subLedger || 'None']}
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-2'>
        {row.transactionType === 'debit' && (
          <>
            <input
              placeholder='Debit'
              type='number'
              className='form-control mb-3 mb-lg-0'
              autoComplete='off'
              style={{maxWidth: '90%'}}
              value={row.amount <= 0 ? '' : row.amount}
              onWheel={handleNumberFieldOnMouseScroll}
              onChange={(e) => {
                const dat = [...data]
                dat[index].amount = Number(e.target.value)
                setData(dat)
              }}
            />
            {!!data[index].amount && showConversion && (
              <div className='form-text'>
                {reportingCurrency} {formatNumber(applicableRate * data[index].amount)}
              </div>
            )}
          </>
        )}
      </div>
      <div className='col-lg-2'>
        {row.transactionType === 'credit' && (
          <>
            <input
              placeholder='Credit'
              type='number'
              className='form-control mb-3 mb-lg-0'
              autoComplete='off'
              style={{maxWidth: '90%'}}
              value={row.amount <= 0 ? '' : row.amount}
              onWheel={handleNumberFieldOnMouseScroll}
              onChange={(e) => {
                const dat = [...data]
                dat[index].amount = Number(e.target.value)
                setData(dat)
              }}
            />
            {!!data[index].amount && showConversion && (
              <div className='form-text'>
                {reportingCurrency} {formatNumber(applicableRate * data[index].amount)}
              </div>
            )}
          </>
        )}
      </div>

      <div className='col-lg-1 d-flex flex-center'>
        {index !== 1 && index !== 0 && (
          <div
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='Delete'
            className='btn btn-bg-light btn-color-danger btn-lg d-flex flex-center'
            style={{height: '50px', width: '50px'}}
            onClick={() => {
              const dat = data.filter((_, j) => index !== j)
              setData(dat)
            }}
          >
            {/* <KTIcon iconName='trash' className='fs-3 btn-danger' /> */}
            <KTIcon iconName='message-minus' className='fs-1 btn-danger px-0' />
          </div>
        )}
      </div>
    </div>
  )
}
