import {useLocation, Link} from 'react-router-dom'
import {useEffect} from 'react'
interface VoucherTypeMap {
  journal: string[]
  receipt: string[]
  payment: string[]
  contra: string[]
  sales: string[]
  purchase: string[]
  debitnote: string[]
  creditnote: string[]
}

const paths = [
  {title: 'Sales', path: '/uploadRecords/sales'},
  {title: 'Purchase', path: '/uploadRecords/purchase'},
  {title: 'Direct Expense', path: '/uploadRecords/expense'},
]

export const HeaderTabs: React.FC<{
  setActiveTab: React.Dispatch<React.SetStateAction<keyof VoucherTypeMap>>
}> = ({setActiveTab}) => {
  const location = useLocation()

  useEffect(() => {
    setActiveTab(location.pathname.split('/')[3] as keyof VoucherTypeMap)
  }, [location.pathname, setActiveTab])

  return (
    <div className='card mb-5'>
      <div className='card-body pt-1 pb-1'>
        <div className='d-flex justify-content-between overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {paths.map((p, i) => (
              <li className='nav-item' key={p.path + i}>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === p.path && 'active')
                  }
                  onClick={() => setActiveTab(p.path.split('/')[3] as keyof VoucherTypeMap)}
                  to={p.path}
                >
                  {p.title}
                </Link>
              </li>
            ))}
          </ul>
          <div className='d-flex align-items-center gap-2'>
            <div>
              <button className='btn btn-primary'> Sample CSV</button>
            </div>
            <div>
              <button className='btn btn-primary'> Upload CSV</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
