import React from 'react'

interface InfoAlertProps {
  message: string
}

const InfoAlert: React.FC<InfoAlertProps> = ({message}) => {
  return (
    <div
      className='alert alert-danger'
      role='alert'
      style={{
        marginBottom: '0',
      }}
    >
      {message}
    </div>
  )
}

export default InfoAlert
