import {useEffect, useMemo, useRef, useState} from 'react'
import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {HeaderTabs} from './HeaderTabs'
import {useLayout} from '../../../_metronic/layout/core'
import {KTCard, KTCardBody, KTIcon, useDebounce} from '../../../_metronic/helpers'
import {ColumnInstance, Row, useTable} from 'react-table'
import Select from 'react-select'
import clsx from 'clsx'
import {getRegisterValues} from './api'
import {getRegisterColumns} from './Columns'
import {Particulars} from './Particulars'
import toast from 'react-hot-toast'
import * as XLSX from 'xlsx'
import {Loader} from '../product-master/loader'

export const Register = () => {
  const [activeTab, setActiveTab] = useState<'sales' | 'purchase' | 'creditnote' | 'debitnote'>(
    'sales'
  )

  return (
    <Routes>
      <Route
        element={
          <>
            <HeaderTabs setActiveTab={setActiveTab} />
            <RegisterView activeTab={activeTab} />
            <Outlet />
          </>
        }
      >
        <Route path='sales' element={<></>} />
        <Route path='purchase' element={<></>} />

        <Route path='creditnote' element={<></>} />
        <Route path='debitnote' element={<></>} />

        <Route index element={<Navigate to='/register/sales' />} />
      </Route>
      <Route path='particulars' element={<Particulars />} />
    </Routes>
  )
}

const RegisterView: React.FC<{activeTab: 'sales' | 'purchase' | 'creditnote' | 'debitnote'}> = ({
  activeTab,
}) => {
  const {company, financialYear} = useLayout()

  const tableRef: any = useRef(null)

  const [toDate, setToDate] = useState<string>('')
  const [fromDate, setFromDate] = useState<string>('')
  const [view, setView] = useState({value: 'Monthly', label: 'Monthly'})
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState<any[]>([])
  const [isDateUpdate, setIsDateUpdate] = useState(false)

  useEffect(() => {
    const [year1, year2] = financialYear?.value.split('-')
    setFromDate(new Date(Number(year1.trim()), 3, 2).toISOString().split('T')[0])
    setToDate(new Date(Number(year2.trim()), 3, 1).toISOString().split('T')[0])
    setIsDateUpdate(true)
  }, [financialYear])

  // const debouncedFrom = useDebounce(fromDate, 1000)
  // const debouncedTo = useDebounce(toDate, 1000)

  const columns = useMemo(
    () => (fromDate && toDate ? getRegisterColumns(activeTab, fromDate, toDate, view?.value) : []),
    [activeTab, fromDate, toDate, view.value]
  )

  useEffect(() => {
    const getData = () => {
      if (
        company?.value &&
        financialYear?.value &&
        fromDate &&
        toDate &&
        view?.value &&
        isDateUpdate
      ) {
        setIsError(false)
        setIsLoading(true)
        getRegisterValues(
          activeTab,
          company?.value,
          financialYear.value,
          fromDate,
          toDate,
          view.value.toLowerCase()
        )
          .then((res) => {
            res && setData(res)
            setIsError(true)
          })
          .finally(() => {
            setIsLoading(false)
            setIsDateUpdate(true)
          })
      }
    }

    getData()
  }, [activeTab, company?.value, financialYear?.value, view, isDateUpdate])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <input
                type='date'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14 me-3'
                placeholder='Search'
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <input
                type='date'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
              <button
                type='button'
                className='btn btn-primary btn-lg ms-3'
                style={{
                  height: '43.59px',
                  width: '43.59px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 0,
                }}
                onClick={() => {
                  if (!fromDate || !toDate)
                    toast.error('Please make sure both the From and To dates are selected')
                  else setIsDateUpdate(true)
                }}
              >
                <KTIcon iconName='arrows-loop' className='fs-1 px-0' />
              </button>
            </div>
            {/* end::Search */}
          </div>
          {/* begin::Card toolbar */}
          <div className='card-toolbar'>
            {/* begin::Group actions */}
            {
              <div className='d-flex justify-content-end gap-3' data-kt-user-table-toolbar='base'>
                <Select
                  value={view}
                  onChange={(option) => setView(option as OptionType)}
                  options={[
                    {value: 'Daily', label: 'Daily'},
                    {value: 'Monthly', label: 'Monthly'},
                    {value: 'Half Yearly', label: 'Half Yearly'},
                    {value: 'Quarterly', label: 'Quarterly'},
                  ]}
                  isSearchable={false}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      marginLeft: '2px',
                      width: '200px',
                    }),
                  }}
                  components={{IndicatorSeparator: () => null}}
                />
                <button
                  type='button'
                  className='btn btn-primary p-0 px-5'
                  disabled={!data.length}
                  onClick={() => {
                    toast.success('Download has been started successfully')
                    const ws = XLSX.utils.table_to_sheet(tableRef.current)

                    // Add additional data to the worksheet if needed

                    const wb = XLSX.utils.book_new()
                    XLSX.utils.book_append_sheet(wb, ws, 'ViewTransactions')

                    // Save the file
                    const fileName = `Register${company.label}_${financialYear.value}.xlsx`
                    XLSX.writeFile(wb, fileName)
                  }}
                >
                  <KTIcon iconName='arrow-down' className='fs-2' />
                  Download
                </button>
                {/* end::Add user */}
              </div>
            }
            {/* end::Group actions */}
          </div>
          {/* end::Card toolbar */}
        </div>
        <KTCardBody
          className={`py-4 ${fromDate && toDate && isDateUpdate ? 'position-relative' : ''}`}
        >
          {!isLoading && (
            <div className='table-responsive'>
              <table
                ref={tableRef}
                id='register_table'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                {...getTableProps()}
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                    {headers.map((column: ColumnInstance<any>) => (
                      <CustomHeaderColumn key={column.id} column={column} />
                    ))}
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row: Row<any>, i) => {
                      prepareRow(row)
                      return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={14}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          {isLoading && <Loader isError={isError} />}
        </KTCardBody>
      </KTCard>
    </>
  )
}

type ColProps = {
  column: ColumnInstance<any>
}

export const CustomHeaderColumn: React.FC<ColProps> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

type RowProps = {
  row: Row<any>
}

export const CustomRow: React.FC<RowProps> = ({row}) => {
  return (
    <>
      <tr {...row.getRowProps()} className='bg-hover-light'>
        {row.cells.map((cell) => {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
            >
              {cell.render('Cell')}
            </td>
          )
        })}
      </tr>
    </>
  )
}

export const styles = {
  borderRadius: '0.475rem',
  boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
  backgroundColor: '#fff',
  color: '#7e8299',
  fontWeight: '500',
  margin: '0',
  width: 'auto',
  padding: '1rem 2rem',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 4rem)',
}

type OptionType = {
  value: string
  label: string
}
