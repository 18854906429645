import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface AgingReportData {
  parentLedger: string
  subLedger: string
  lastSaleDate?: string
  lastPurchaseDate?: string
  transactionAmount: number
  setOffAmount: number
  pendingInvoices: number
  totalOutstanding: number
  unReconciledAmount: number
  '0-15 days': number
  '16-30 days': number
  '31-60 days': number
  '61-90 days': number
  '91-180 days': number
  '>180 days': number
}

export const getAgingReport = async (
  company_id: string,
  fy_name: string,
  to_date: string,
  report_type: 'receivables' | 'payables'
): Promise<{data: AgingReportData[]} | undefined> => {
  return await axios
    .get(`${API_URL}/accounting/report/aging-report`, {
      params: {
        company_id,
        fy_name,
        to_date,
        report_type,
      },
    })
    .then((response: AxiosResponse<{data: AgingReportData[]}>) => response.data)
    .then((response: {data: AgingReportData[]}) => response)
}
