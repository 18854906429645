type Props = {
  className: string
  description: string
  stats: string | null
  diff_per?: number
}

const CardsWidget7 = ({className, description, stats, diff_per = 0}: Props) => (
  <div className={`card ${className}`}>
    <div className='card-header pt-2'>
      <div className='card-title d-flex flex-column'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex'>
            <span className='fs-2x fw-bold text-dark me-2 lh-1 ls-n2'>{stats ?? '-'}</span>
            {description === 'Difference' ? (
              <>
                {diff_per >= 0 ? (
                  <div className='m-0 ms-1 p-0'>
                    <span className='bg-light-success d-flex pt-1 ps-4 pb-1 pe-4'>
                      <i className='fa-solid fa-angle-up text-success mt-1'></i>
                      <span className='text-success fs-6 ms-1'>{diff_per}%</span>
                    </span>
                  </div>
                ) : (
                  <div className='m-0 ms-1 p-0'>
                    <span className='bg-light-danger d-flex pt-1 ps-4 pb-1 pe-4 '>
                      <i className='fa-solid fa-angle-down text-danger mt-1'></i>
                      <span className='text-danger fs-6 ms-1'>{diff_per}%</span>
                    </span>
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>{description}</span>
        </div>
      </div>
    </div>
  </div>
)
export {CardsWidget7}
