import axios, { AxiosResponse } from "axios";
import { SetOffInvoiceResp } from "./EnterTransactionsAPI";

const API_URL = process.env.REACT_APP_THEME_API_URL;

export interface Transaction {
  dbId?: number;
  voucherType: string;
  voucherName: string;
  voucherNumber: number;
  voucherDate: string;

  transactingCurrency: string;
  reportingCurrency: string;

  totalAmount: number;
  exchangeMultiplier: number;
  narration: string;

  invoiceNumber: number;
  invoiceAmount: number;
  invoiceDescription: string;
  invoiceDetails: SetOffInvoiceResp[];

  transactionDetails: {
    parentLedger: string;
    subLedger: string | null;
    ledgerCode: string;
    transactionType: 'credit' | 'debit';
    transactionAmount: number;
  }[];
}

export const getTransactionsList = (companyId: string, fy: string, voucherType: string): Promise<{ transactions: Transaction[] } | undefined> => {
  return axios
    .get(`${API_URL}/accounting/transactions/details/${companyId}/${fy}/${voucherType}`)
    .then((response: AxiosResponse<{ transactions: Transaction[] }>) => response.data)
    .then((response: { transactions: Transaction[] }) => response)
}

export const deleteTransaction = (companyId: string, fy: string, voucherName: string, voucherNumber: number): Promise<{ detail: string } | undefined> => {
  return axios
    .delete(`${API_URL}/accounting/transactions/${companyId}/${fy}/${voucherName}?voucher_number=${voucherNumber}`)
    .then((response: AxiosResponse<{ detail: string }>) => response.data)
    .then((response: { detail: string }) => response)
}