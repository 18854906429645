import {createContext, useContext, useState} from 'react'

const LoaderContext = createContext({
  loading: false,

  error: false,
  setLoading: (v) => {},

  setError: (v) => {},
})

const LoaderProvider = ({children}) => {
  const [loading, setLoading] = useState(false)

  const [error, setError] = useState(false)

  return (
    <LoaderContext.Provider value={{loading, setLoading, error, setError}}>
      {children}
    </LoaderContext.Provider>
  )
}

const useLoaderContext = () => {
  const loaderContext = useContext(LoaderContext)
  return loaderContext
}

export {LoaderProvider as default, useLoaderContext}
