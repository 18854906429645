import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {CompanyDetail} from '../modules/apps/user-management/users-list/company-detail'
import Products from '../modules/product-master'
import Transactions from '../modules/transactions'
import {Settings} from '../modules/settings/'
import {ProfitAndLoss} from '../modules/profitandloss'
import {BalanceSheet} from '../modules/balancesheet'
import {Register} from '../modules/register'
import UploadRecords from '../modules/uploadeRecords'
import LoaderProvider from '../modules/loaders/LoaderProvider'
import {PageTitle} from '../../_metronic/layout/core'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />

        <Route
          path='companies/'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='company/:id'
          element={
            <SuspensedView>
              <CompanyDetail />
            </SuspensedView>
          }
        />

        <Route
          path='products/*'
          element={
            <SuspensedView>
              <Products />
            </SuspensedView>
          }
        />

        <Route
          path='transactions/*'
          element={
            <SuspensedView>
              <Transactions />
            </SuspensedView>
          }
        />

        <Route
          path='settings/'
          element={
            <SuspensedView>
              <Settings />
            </SuspensedView>
          }
        />
        <Route
          path='profitandloss/'
          element={
            <SuspensedView>
              <PageTitle>Profit And Loss Report</PageTitle>
              <ProfitAndLoss />
            </SuspensedView>
          }
        />
        <Route
          path='balancesheet/'
          element={
            <SuspensedView>
              <BalanceSheet />
            </SuspensedView>
          }
        />
        <Route
          path='uploadRecords/*'
          element={
            <SuspensedView>
              <UploadRecords />
            </SuspensedView>
          }
        />
        <Route
          path='register/*'
          element={
            <SuspensedView>
              <PageTitle>Register</PageTitle>
              <Register />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
