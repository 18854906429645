import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {HeaderTabs} from './HeaderTabs'
import {KTCard, KTIcon, KTCardBody, useDebounce} from '../../../../_metronic/helpers'
import toast from 'react-hot-toast'
import {useLayout} from '../../../../_metronic/layout/core'
import {AgingReportData, getAgingReport} from '../API/AgingReport'
import {getAgingReportColumns} from './Columns'
import {ColumnInstance, Row, useTable} from 'react-table'
import clsx from 'clsx'
import jsPDF from 'jspdf'
import {Loader} from '../../product-master/loader'

const AgingReport: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'receivables' | 'payables'>('receivables')

  return (
    <Routes>
      <Route
        element={
          <>
            <HeaderTabs setActiveTab={setActiveTab} />
            <Report activeTab={activeTab} />
            <Outlet />
          </>
        }
      >
        <Route path='receivables' element={<></>} />
        <Route path='payables' element={<></>} />

        <Route index element={<Navigate to='/transactions/aging-report/receivables' />} />
      </Route>
    </Routes>
  )
}

const Report: React.FC<{activeTab: 'receivables' | 'payables'}> = ({activeTab}) => {
  const {company, financialYear} = useLayout()
  const tableRef = useRef(null)

  const columns = useMemo(() => getAgingReportColumns(activeTab), [activeTab])

  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState<AgingReportData[]>([])

  const debouncedDate = useDebounce(toDate, 1000)

  useEffect(() => {
    const getData = () => {
      if (company?.value && financialYear?.value && debouncedDate) {
        setIsError(false)
        setIsLoading(true)
        getAgingReport(company?.value, financialYear.value, debouncedDate, activeTab)
          .then((res) => res && setData(res?.data))
          .catch((err) => {
            toast.error(
              err.detail || 'Something went wrong. Please check the company and FY selected'
            )
            setIsError(true)
          })
          .finally(() => setIsLoading(false))
      }
    }

    getData()
  }, [activeTab, company?.value, financialYear?.value, debouncedDate])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <input
                type='date'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            {/* end::Search */}
          </div>
          {/* begin::Card toolbar */}
          <div className='card-toolbar'>
            {/* begin::Group actions */}
            {
              <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                {/* begin::Add user */}
                <button
                  type='button'
                  className='btn btn-primary'
                  disabled={!data.length}
                  onClick={() => {
                    toast.success('Download has been started successfully')
                    const doc = new jsPDF({
                      format: 'a2',
                      unit: 'px',
                      hotfixes: ['px_scaling'],
                    })

                    tableRef.current &&
                      doc.html(tableRef.current, {
                        callback: (doc) => {
                          const pageWidth = doc.internal.pageSize.getWidth()
                          const pageHeight = doc.internal.pageSize.getHeight()

                          const header = `${company.label} | ${financialYear.value}`
                          doc.setFontSize(26)
                          doc.setFont('Arial', 'italic', 600)
                          doc.text(header, (pageWidth - 300) / 2, 30)

                          const footer = `${new Date().getFullYear().toString()}© String Labs`
                          doc.setFontSize(20)
                          doc.text(footer, 30, pageHeight - 20 - 10)
                          const totalPages = doc.getNumberOfPages()

                          doc.deletePage(totalPages - 1)
                          doc.deletePage(totalPages - 2)
                          doc.deletePage(totalPages - 4)
                          doc.deletePage(totalPages - 3)

                          doc.save(`Aging Report - ${company.label}(${financialYear.value})`)
                        },
                        margin: [80, 30, 30, 30],
                        autoPaging: 'text',
                      })
                  }}
                >
                  <KTIcon iconName='arrow-down' className='fs-2' />
                  Download
                </button>
                {/* end::Add user */}
              </div>
            }
            {/* end::Group actions */}
          </div>
          {/* end::Card toolbar */}
        </div>
        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              ref={tableRef}
              id='aging_report_table'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              {...getTableProps()}
            >
              <thead>
                <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                  {headers.map((column: ColumnInstance<any>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<any>, i) => {
                    prepareRow(row)
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                  })
                ) : (
                  <tr>
                    <td colSpan={14}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {isLoading && <Loader isError={isError} />}
        </KTCardBody>
      </KTCard>
    </>
  )
}

export default AgingReport

type ColProps = {
  column: ColumnInstance<AgingReportData>
}

export const CustomHeaderColumn: React.FC<ColProps> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

type RowProps = {
  row: Row<AgingReportData>
}

export const CustomRow: React.FC<RowProps> = ({row}) => {
  return (
    <>
      <tr {...row.getRowProps()} className='bg-hover-light'>
        {row.cells.map((cell) => {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
            >
              {cell.render('Cell')}
            </td>
          )
        })}
      </tr>
    </>
  )
}

export const styles = {
  borderRadius: '0.475rem',
  boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
  backgroundColor: '#fff',
  color: '#7e8299',
  fontWeight: '500',
  margin: '0',
  width: 'auto',
  padding: '1rem 2rem',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 4rem)',
}
