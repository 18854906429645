import {useMemo, useEffect, useState, useRef} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {TransactionList, getTrialBalanceLedgersList} from '../API/TrialBalanceAPI'
import {useLayout, usePageData} from '../../../../_metronic/layout/core'
import {toast} from 'react-hot-toast'
import {KTCard, KTCardBody, KTIcon, formatNumber, useDebounce} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {Loader} from '../../product-master/loader'
import {ColumnInstance, Row, Column, useTable} from 'react-table'
import {deleteTransaction} from '../API/ViewTransactionsAPI'
import {Modal} from 'react-bootstrap'
import jsPDF from 'jspdf'
import {CardsWidget7} from '../../../../_metronic/partials/widgets'

type Params = {
  parent_group: string
  sub_group?: string
  from: string
  to: string
  parent_ledger: string
  sub_ledger?: string
}

const LedgerListColumns: ReadonlyArray<Column<TransactionList>> = [
  {
    Header: () => <th className='min-w-125px ps-4'>Voucher Number</th>,
    accessor: 'voucherNumber',
    Cell: ({...props}) => (
      <div
        style={{maxWidth: '50%'}}
        className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4'
      >
        <div className='d-flex align-items-center'>
          <div className='fs-2 fw-bolder'> {props.data[props.row.index].voucherNumber}</div>
        </div>

        <div className='fw-bold fs-6 text-gray-400'>{props.data[props.row.index].voucherType}</div>
      </div>
    ),
  },
  {
    Header: () => <th className='min-w-125px ps-4'>Voucher Date</th>,
    accessor: 'voucherDate',
  },
  {
    Header: () => <th className='min-w-125px'>Amount</th>,
    accessor: 'amount',
    Cell: ({...props}) => (
      <div className='d-flex flex-column '>
        <div className='text-dark fw-bold'>
          {props.data[props.row.index].reportingCurrency}{' '}
          {formatNumber(props.data[props.row.index].amount)}
        </div>
        <>
          {props.data[props.row.index].transactingCurrency !== null &&
            props.data[props.row.index].transactingCurrency !==
              props.data[props.row.index].reportingCurrency && (
              <div className='text-dark fw-bold'>
                ({props.data[props.row.index].transactingCurrency}{' '}
                {formatNumber(
                  props.data[props.row.index].amount /
                    props.data[props.row.index].exchangeMultiplier
                )}
                )
              </div>
            )}
        </>
      </div>
    ),
  },

  {
    Header: () => <th className='min-w-125px'>Transactions</th>,
    accessor: 'transactionDetails',
    Cell: ({...props}) => (
      <div className='d-flex flex-column '>
        {props.data[props.row.index].transactionDetails.length > 0
          ? props.data[props.row.index].transactionDetails.map((tr) => (
              <div>
                <span className='text-dark fw-bolder'>{tr.parentLedger}&nbsp;&nbsp;</span>
                <span className='text-dark fw-semibold'>
                  {tr.subLedger && ` - ${tr.subLedger} `}
                </span>
                <span className={tr.transactionType === 'credit' ? 'text-success' : 'text-danger'}>
                  {props.data[props.row.index].reportingCurrency}{' '}
                  {formatNumber(tr.transactionAmount)}
                  {tr.transactionType === 'credit' ? ' CR' : ' DR'}
                  {props.data[props.row.index].reportingCurrency !==
                    props.data[props.row.index].transactingCurrency &&
                    `  (${props.data[props.row.index].transactingCurrency} ${formatNumber(
                      props.data[props.row.index].amount /
                        props.data[props.row.index].exchangeMultiplier
                    )})`}
                </span>
              </div>
            ))
          : '-'}
      </div>
    ),
  },
  {
    Header: () => <th className='min-w-125px'>Actions</th>,
    accessor: 'voucherType',
    Cell: (props) => {
      const navigate = useNavigate()
      const {company, financialYear} = useLayout()

      const [showDeleteModal, setShowDeleteModal] = useState(false)

      const handleEdit = () => {
        !!props.data[props.row.index].transactionDetails.length &&
          navigate(
            `/transactions/enter/${props.data[props.row.index].voucherType?.toLowerCase()}`,
            {
              state: {
                transaction: props.data[props.row.index],
              },
            }
          )
      }

      const handleDelete = async () => {
        if (
          company?.value &&
          financialYear?.value &&
          props.data[props.row.index].voucherType !== null
        )
          deleteTransaction(
            company?.value,
            financialYear?.value,
            props.data[props.row.index].voucherType || '',
            props.data[props.row.index].voucherNumber
          )
            .then((res) => res?.detail && toast.success(res.detail))
            .catch((err) => err && toast.error(err))
            .finally(() => setShowDeleteModal(false))
      }

      return (
        <>
          <div className='d-flex justify-content-start flex-shrink-0'>
            <div
              onClick={handleEdit}
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title='Edit'
              className={clsx(
                'btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1',
                !props.data[props.row.index].transactionDetails.length && 'disabled'
              )}
            >
              <KTIcon iconName='pencil' className={clsx('fs-3')} />
            </div>
            <div
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title='Delete'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={() => setShowDeleteModal(true)}
            >
              <KTIcon iconName='trash' className='fs-3' />
            </div>
          </div>

          <Modal
            className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
            id='disable_financial_year'
            role='dialog'
            data-backdrop='false'
            aria-hidden='true'
            tabIndex='-1'
            show={showDeleteModal}
            animation={false}
          >
            <div className='modal-content'>
              {/*begin::Header*/}
              <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
                <h5 className='fw-bold m-0'>Delete Transaction</h5>
                <div className='d-flex ms-2'>
                  {/*begin::Close*/}
                  <div
                    className='btn btn-icon btn-sm btn-light-primary ms-2'
                    data-bs-dismiss='modal'
                    onClick={() => setShowDeleteModal(false)}
                  >
                    <KTIcon className='fs-1' iconName='cross' />
                  </div>
                  {/*end::Close*/}
                </div>
              </div>
              {/*end::Header*/}
              <div className='text-center py-5 px-5'>
                Are you sure you want to delete this Transaction? Please note you cannot undo this
                action.
              </div>
              <div className='text-center pt-5 pb-5'>
                <button
                  type='reset'
                  onClick={() => setShowDeleteModal(false)}
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Cancel
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={handleDelete}
                >
                  Proceed
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    },
  },
]

export const LedgerDetails = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const {company, financialYear} = useLayout()
  const {setPageTitle} = usePageData()
  const tableRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState<TransactionList[]>([])
  const [totals, setTotals] = useState({
    closingBalance: 0,
    creditAmount: 0,
    debitAmount: 0,
    openingBalance: 0,
  })
  const [searchTerm, setSearchTerm] = useState<string>('')

  const columns = useMemo(() => LedgerListColumns, [])

  const {parent_group, sub_group, from, to, parent_ledger, sub_ledger} = useMemo(() => {
    let obj: Params = {
      parent_group: '',
      sub_group: '',
      from: '',
      to: '',
      parent_ledger: '',
      sub_ledger: undefined,
    }

    location.search
      .substring(1)
      .split('&')
      .map((p) => {
        const val = p.split('=')
        obj[val[0] as keyof Params] = decodeURI(val[1])
        return null
      })

    return obj
  }, [location.search])

  useEffect(() => {
    setPageTitle('Ledger Transactions')
  }, [setPageTitle])

  useEffect(() => {
    const getData = () => {
      if (company.value && financialYear?.value) {
        setIsError(false)
        setIsLoading(true)
        getTrialBalanceLedgersList({
          company_id: company.value,
          fy_name: financialYear.value,
          from_date: from,
          to_date: to,
          parent_ledger,
          sub_ledger,
        })
          .then((res) => {
            res && setData(res.transactionList)
            res &&
              setTotals({
                openingBalance: res.openingBalance,
                closingBalance: res.closingBalance,
                creditAmount: res.creditAmount,
                debitAmount: res.debitAmount,
              })
          })
          .catch((err) => {
            toast.error(err.detail || 'Something went wrong. Please try again later')
            setIsError(true)
          })
          .finally(() => setIsLoading(false))
      }
    }

    getData()
  }, [
    company.value,
    financialYear.value,
    from,
    parent_group,
    parent_ledger,
    sub_group,
    sub_ledger,
    to,
  ])

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const filteredData: TransactionList[] = useMemo(() => {
    if (debouncedSearchTerm?.length) {
      return data.filter(
        (d) =>
          d.voucherNumber.toString().toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d?.voucherType?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d?.voucherDate?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d?.transactionDetails.some(
            (t) =>
              t.parentLedger?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
              t.subLedger?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
              t.transactionAmount
                ?.toString()
                .toLowerCase()
                .includes(debouncedSearchTerm.toLowerCase())
          )
      )
    }
    return []
  }, [debouncedSearchTerm, data])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: debouncedSearchTerm?.length ? filteredData : data,
  })

  return (
    <KTCard>
      <div className='card-header border-0 pt-3'>
        <div className='card-title'>
          <div
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='Go Back'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            style={{marginRight: '5px'}}
            onClick={() => navigate(-1)}
          >
            <KTIcon iconName='arrow-left' className='fs-2' />
          </div>

          <div className='text-dark fw-bolder fs-2 my-1 fw-bolder'>
            <div>
              {parent_group} {sub_group ? ` | ${sub_group}` : ''}
            </div>
            <div className='text-muted'>
              Ledger : {parent_ledger} {sub_ledger ? ` | ${sub_ledger}` : ''}
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-3'>
        <div className='card-title'>
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className='card-toolbar'>
          {/* begin::Group actions */}
          {
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              {/* begin::Add user */}
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  toast.success('Download has been started successfully')
                  const doc = new jsPDF({
                    format: 'a2',
                    unit: 'px',
                    hotfixes: ['px_scaling'],
                  })

                  tableRef.current &&
                    doc.html(tableRef.current, {
                      callback: (doc) => {
                        const pageWidth = doc.internal.pageSize.getWidth()
                        const pageHeight = doc.internal.pageSize.getHeight()

                        const header = `${company.label} | ${financialYear.value}`
                        doc.setFontSize(26)
                        doc.setFont('Arial', 'italic', 600)
                        doc.text(header, (pageWidth - 300) / 2, 30)

                        const footer = `${new Date().getFullYear().toString()}© String Labs`
                        doc.setFontSize(20)
                        doc.text(footer, 30, pageHeight - 20 - 10)
                        const totalPages = doc.getNumberOfPages()

                        doc.deletePage(totalPages - 1)
                        doc.deletePage(totalPages - 2)
                        doc.deletePage(totalPages - 4)
                        doc.deletePage(totalPages - 3)

                        doc.save(
                          `Ledger wise Transactions - ${company.label}(${financialYear.value})`
                        )
                      },
                      margin: [80, 30, 30, 30],
                      autoPaging: 'text',
                    })
                }}
              >
                <KTIcon iconName='arrow-down' className='fs-2' />
                Download
              </button>
              {/* end::Add user */}
            </div>
          }
          {/* end::Group actions */}
        </div>
        {/* end::Card toolbar */}
      </div>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            ref={tableRef}
            id='ledger_detail_table'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<TransactionList>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<TransactionList>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              {
                <tr key='footer'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td colSpan={2}>
                    <div className='d-flex flex-end'>
                      <CardsWidget7
                        className='border border-dashed border-gray-300 me-5'
                        stats={formatNumber(totals.openingBalance)}
                        description='Opening Balance'
                      />

                      <CardsWidget7
                        className='border border-dashed border-gray-300 me-5'
                        stats={formatNumber(totals.debitAmount)}
                        description='Debit Amount'
                      />

                      <CardsWidget7
                        className='border border-dashed border-gray-300 me-5'
                        stats={formatNumber(totals.creditAmount)}
                        description='Credit Amount'
                      />

                      <CardsWidget7
                        className='border border-dashed border-gray-300'
                        stats={formatNumber(totals.closingBalance)}
                        description='Closing Balance'
                      />
                    </div>
                  </td>
                </tr>
              }
            </tfoot>
          </table>
        </div>
        {isLoading && <Loader isError={isError} />}
      </KTCardBody>
    </KTCard>
  )
}

type ColProps = {
  column: ColumnInstance<TransactionList>
}

export const CustomHeaderColumn: React.FC<ColProps> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

type RowProps = {
  row: Row<TransactionList>
}

export const CustomRow: React.FC<RowProps> = ({row}) => {
  return (
    <>
      <tr {...row.getRowProps()} className='bg-hover-light'>
        {row.cells.map((cell) => {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
            >
              {cell.render('Cell')}
            </td>
          )
        })}
      </tr>
    </>
  )
}

export const styles = {
  borderRadius: '0.475rem',
  boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
  backgroundColor: '#fff',
  color: '#7e8299',
  fontWeight: '500',
  margin: '0',
  width: 'auto',
  padding: '1rem 2rem',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 4rem)',
}
