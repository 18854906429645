import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface User {
  dbId?: number
  userId: string
  firstName: string
  lastName: string
  emailId: string
  password: string | null
  role: string
  region: string
  address: string
  countryCode: string
  mobile: number
  isSuperAdmin: boolean
  companyAccess: string[]
}

export const createNewUser = async (body: User): Promise<{detail: string} | undefined> => {
  return await axios
    .post(`${API_URL}/user/add`, body)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export const modifyExistingUser = async (body: User): Promise<{detail: string} | undefined> => {
  return await axios
    .post(`${API_URL}/user/modify`, body)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export const getExistingUsers = async (): Promise<{users: User[]} | undefined> => {
  return await axios
    .get(`${API_URL}/user/`)
    .then((response: AxiosResponse<{users: User[]}>) => response.data)
    .then((response: {users: User[]}) => response)
}
