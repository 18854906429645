import {ColumnInstance, Row, useTable} from 'react-table'
import {KTCard, KTIcon, KTCardBody, useDebounce} from '../../../../_metronic/helpers'
import {Loader} from '../../product-master/loader'
import {CustomHeaderColumn, CustomRow, styles} from './Utils'
import {useState, useMemo, useCallback, useEffect, useRef} from 'react'
import {useLayout} from '../../../../_metronic/layout/core'
import {getViewTransactionColumns} from './Columns'
import {Transaction, getTransactionsList} from '../API/ViewTransactionsAPI'
import {toast} from 'react-hot-toast'
import * as XLSX from 'xlsx'

export const ViewTransactionsTable: React.FC<{activeTab: string}> = ({activeTab}) => {
  const {company, financialYear} = useLayout()
  const tableRef: any = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState<Transaction[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const columns = useMemo(
    () => getViewTransactionColumns(activeTab, showDeleteModal, setShowDeleteModal),
    [activeTab, showDeleteModal]
  )

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const filteredData: Transaction[] = useMemo(() => {
    if (debouncedSearchTerm?.length) {
      return data.filter(
        (d) =>
          d?.voucherName?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d?.voucherNumber?.toString().toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d?.narration?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d?.transactionDetails.some(
            (t) =>
              t.parentLedger?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
              t.subLedger?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
              t.transactionAmount
                .toString()
                ?.toLowerCase()
                .includes(debouncedSearchTerm.toLowerCase())
          )
      )
    }
    return []
  }, [debouncedSearchTerm, data])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: debouncedSearchTerm?.length ? filteredData : data,
  })

  const getAllGroups = useCallback(async () => {
    if (company && financialYear && !!activeTab) {
      try {
        setIsError(false)
        setIsLoading(true)
        const res = await getTransactionsList(company?.value, financialYear.value, activeTab)
        res && setData(res.transactions)
      } catch (err) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }
  }, [company, financialYear, activeTab])

  useEffect(() => {
    !showDeleteModal && getAllGroups()
  }, [getAllGroups, showDeleteModal])

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {/* end::Search */}
          </div>
          {/* begin::Card toolbar */}
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <button
                type='button'
                className='btn btn-primary'
                disabled={!data.length}
                onClick={() => {
                  toast.success('Download has been started successfully')
                  const ws = XLSX.utils.table_to_sheet(tableRef.current)

                  // Add additional data to the worksheet if needed

                  const wb = XLSX.utils.book_new()
                  XLSX.utils.book_append_sheet(wb, ws, 'ViewTransactions')

                  // Save the file
                  const fileName = `ViewTransaction_${company.label}_${financialYear.value}.xlsx`
                  XLSX.writeFile(wb, fileName)
                }}
              >
                <KTIcon iconName='arrow-down' className='fs-2' />
                Download
              </button>
            </div>
          </div>
          {/* end::Card toolbar */}
        </div>
        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              ref={tableRef}
              id='view_transactions_table'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              {...getTableProps()}
            >
              <thead>
                <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                  {headers.map((column: ColumnInstance<any>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<any>, i) => {
                    prepareRow(row)
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {isLoading && <Loader isError={isError} />}
        </KTCardBody>
      </KTCard>
    </>
  )
}
