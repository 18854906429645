import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface ProductVoucher {
  dbId?: number
  voucherType: string
  voucherName: string
}

export const getProductVouchers = async (
  companyId: string
): Promise<ProductVoucher[] | undefined> => {
  return await axios
    .get(`${API_URL}/product-master/voucher/${companyId}`)
    .then((response: AxiosResponse<ProductVoucher[]>) => response.data)
    .then((response: ProductVoucher[]) => response)
}

export interface CreateProductVoucherBody {
  dbId?: number
  companyId: string
  voucherType: string
  voucherName: string
}

export const createProductVoucher = async (
  body: CreateProductVoucherBody
): Promise<{detail: string} | undefined> => {
  return await axios
    .post(`${API_URL}/product-master/voucher/save`, body)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export const deleteProductVoucher = async (
  companyId: string
): Promise<{detail: string} | undefined> => {
  return await axios
    .delete(`${API_URL}/product-master/voucher/${companyId}`)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export const downloadPdf = async (
  records: ProductVoucher[],
  companyId: string,
  fyYear: string
): Promise<any | undefined> => {
  return await axios.post<any>(`${API_URL}/pdfDownload`, {
    records,
    companyId,
    fyYear,
  })
}
