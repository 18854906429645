import { createColumnHelper } from "@tanstack/react-table";
import { KTIcon } from "../../../_metronic/helpers";

export interface IProfitAndLoss {
  classification: string;
  parentLedger?: string;
  subLedger?: string | null;
  amount: string;
  children?: IProfitAndLoss[];
}

const columnHelper = createColumnHelper<IProfitAndLoss>();

const getProfitAndLossColumns = (from?: string, to?: string) => ([
  columnHelper.accessor('classification', {
    header: () => <span className='px-3'>Classification</span>,
    cell: ({ row, getValue }) => {

      return (
        <div className='max-w-125px px-3 fw-bolder text-dark fs-2'>
          {row.getCanExpand() && row.original.children && (
            <>
              {<span
                {...{
                  onClick: (e) => {
                    e.stopPropagation();
                    row.toggleExpanded();
                  },
                  style: { cursor: 'pointer' },
                }}
              >
                {row.getIsExpanded() ? <KTIcon iconName="up" className='text-dark fs-2' /> : <KTIcon iconName="down" className='text-dark fs-2' />}
              </span>}
            </>
          )}{' '}
          {getValue()}
        </div>)
    },
  }),
  columnHelper.accessor('parentLedger', {
    cell: ({ getValue, row }) => {
      let url = `/transactions/trial-balance/ledger?from=${from}&to=${to}`;
      url += `&parent_ledger=${getValue()}`;
      row.original.subLedger && (url += `&sub_ledger=${row.original.subLedger}`);
      return (<a href={url} className='text-dark text-hover-primary'>
        {getValue()}{row.original.subLedger && ` - ${row.original.subLedger}`}
      </ a>);
    },
  }),
  columnHelper.accessor('amount', {
    header: ({ table }) => <div>
      <span
        onClick={() => table.setExpanded({ '0': false, '1': false, '2': false, '3': false })}
        style={{ cursor: 'pointer', marginRight: '10px' }}
      >
        <i className='bi bi-dash-circle-fill fs-1 text-dark' />
      </span>
      <span
        onClick={() => table.setExpanded({ '0': true, '1': true, '2': true, '3': true })}
        style={{ cursor: 'pointer' }}
      >
        <i className='bi bi-plus-circle-fill fs-1 text-dark' />
      </span>
    </div>,
    cell: ({ row, getValue }) => (
      <span className={row.depth === 0 ? 'text-dark fw-bolder fs-2' : ''}>
        {(getValue())}
      </span>),
  }),
]);

export { getProfitAndLossColumns };