import {useEffect, useMemo, useRef, useState} from 'react'
import {useLayout} from '../../../_metronic/layout/core'
import {KTCard, KTCardBody, KTIcon, useDebounce} from '../../../_metronic/helpers'
import {
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import toast from 'react-hot-toast'
import {IProfitAndLoss, getProfitAndLossColumns} from './columns'
import {CardsWidget7} from '../../../_metronic/partials/widgets'
import {getPLReport} from './api'
import * as XLSX from 'xlsx'
import {Loader} from '../product-master/loader'

export const ProfitAndLoss = () => {
  const {company, financialYear} = useLayout()
  const [data1, setData1] = useState<IProfitAndLoss[]>([])
  const [data2, setData2] = useState<IProfitAndLoss[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [from, setFrom] = useState<string>('')
  const [to, setTo] = useState<string>('')
  const [isDateUpdate, setIsDateUpdate] = useState(false)
  const [totals, setTotals] = useState<{
    grossProfit: string | null
    grossLoss: string | null
    netProfit: string | null
    netLoss: string | null
  }>({
    grossProfit: '',
    grossLoss: '',
    netProfit: '',
    netLoss: '',
  })

  // console.log('render')
  // const debouncedFrom = useDebounce(from, 1000)
  // const debouncedTo = useDebounce(to, 1000)

  useEffect(() => {
    const [year1, year2] = financialYear?.value.split('-')
    setFrom(new Date(Number(year1.trim()), 3, 2).toISOString().split('T')[0])
    setTo(new Date(Number(year2.trim()), 3, 1).toISOString().split('T')[0])
    setIsDateUpdate(true)
  }, [financialYear])

  useEffect(() => {
    const getData = async () => {
      if (company?.value && financialYear?.value && from && to && isDateUpdate) {
        try {
          setIsError(false)
          setIsLoading(true)
          const res = await getPLReport({
            company_id: company.value,
            fy_name: financialYear.value,
            from_date: from,
            to_date: to,
          })

          if (res) {
            setTotals({
              grossProfit: res?.grossProfit,
              grossLoss: res?.grossLoss,
              netProfit: res.netProfit,
              netLoss: res.netLoss,
            })

            setData1([
              {
                classification: 'Direct Expenses',
                amount: res['Direct Expenses'].amount,
                children: res['Direct Expenses'].data.map((d) => ({
                  ...d,
                  classification: '',
                })),
              },
              {
                classification: 'Direct Income',
                amount: res['Direct Income'].amount,
                children: res['Direct Income'].data.map((d) => ({
                  ...d,
                  classification: '',
                })),
              },
            ])

            setData2([
              {
                classification: 'Indirect Expenses',
                amount: res['Indirect Expenses'].amount,
                children: res['Indirect Expenses'].data.map((d) => ({
                  ...d,
                  classification: '',
                })),
              },
              {
                classification: 'Indirect Income',
                amount: res['Indirect Income'].amount,
                children: res['Indirect Income'].data.map((d) => ({
                  ...d,
                  classification: '',
                })),
              },
            ])
          }
        } catch (err) {
          setIsError(true)
        } finally {
          setIsLoading(false)
          setIsDateUpdate(false)
        }
      }
    }
    getData()
  }, [company?.value, from, to, financialYear?.value, isDateUpdate])

  const columns = useMemo(() => getProfitAndLossColumns(from, to), [from, to])

  const [expanded, setExpanded] = useState<ExpandedState>({
    '0': true,
    '1': true,
    '2': true,
    '3': true,
  })

  const table1 = useReactTable({
    columns,
    data: data1,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.children,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
  })
  const table2 = useReactTable({
    columns,
    data: data2,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.children,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
  })
  const tableRef1 = useRef(null)
  const tableRef2 = useRef(null)
  const handleDownload = () => {
    toast.success('Download has been started successfully')

    // Get data from tables
    const data1 = tableRef1.current
    const data2 = tableRef2.current

    // Create worksheets for each table
    const ws1 = XLSX.utils.table_to_sheet(data1)
    const ws2 = XLSX.utils.table_to_sheet(data2)

    // Combine data into a single worksheet
    const combinedData = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(combinedData, ws1, 'Direct Expense')
    XLSX.utils.book_append_sheet(combinedData, ws2, 'Indirect Expense')

    // Save the file
    const fileName = `Profit-loss${company.label}_${financialYear.value}.xlsx`
    XLSX.writeFile(combinedData, fileName)
  }

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              {/* <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' /> */}
              <input
                type='date'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14 me-3'
                placeholder='Search'
                value={from}
                onChange={(e) => setFrom(e.target.value)}
              />
              <input
                type='date'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14 ms-3'
                placeholder='Search'
                value={to}
                onChange={(e) => setTo(e.target.value)}
              />
              <button
                type='button'
                className='btn btn-primary btn-lg ms-3'
                style={{
                  height: '43.59px',
                  width: '43.59px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 0,
                }}
                onClick={() => {
                  if (!from || !to)
                    toast.error('Please make sure both the From and To dates are selected')
                  else setIsDateUpdate(true)
                }}
              >
                <KTIcon iconName='arrows-loop' className='fs-1 px-0' />
              </button>
            </div>
            {/* end::Search */}
          </div>
          {/* begin::Card toolbar */}
          <div className='card-toolbar'>
            {/* begin::Group actions */}
            {
              <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                {/* begin::Add user */}
                <button
                  disabled={!data1.length || !data2.length}
                  type='button'
                  className='btn btn-primary'
                  onClick={handleDownload}
                >
                  <KTIcon iconName='arrow-down' className='fs-2' />
                  Download
                </button>
                {/* end::Add user */}
              </div>
            }
            {/* end::Group actions */}
          </div>
          {/* end::Card toolbar */}
        </div>
        <KTCardBody className={`py-4 ${from && to && isDateUpdate ? 'position-relative' : ''}`}>
          {!isLoading && (
            <>
              <div className='table-responsive'>
                <table ref={tableRef1} className='table align-middle fs-6  dataTable '>
                  {data1.length > 0 ? (
                    <>
                      <thead>
                        {table1.getHeaderGroups().map((headerGroup) => (
                          <tr
                            key={headerGroup.id}
                            className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'
                          >
                            {headerGroup.headers.map((header) => (
                              <th
                                key={header.id}
                                colSpan={header.colSpan}
                                style={{
                                  border: '1px solid #e1e3ea',
                                }}
                              >
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody className='text-gray-600 fw-bold'>
                        {table1.getRowModel().rows.map((row) => (
                          <>
                            <tr
                              key={row.id}
                              onClick={
                                row.getCanExpand() ? row.getToggleExpandedHandler() : undefined
                              }
                              className='bg-hover-light'
                            >
                              {row.getVisibleCells().length > 0 ? (
                                row
                                  .getVisibleCells()
                                  .map((cell) => (
                                    <td key={cell.id}>
                                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                  ))
                              ) : (
                                <td colSpan={7}>
                                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    No matching records found
                                  </div>
                                </td>
                              )}
                            </tr>
                          </>
                        ))}
                      </tbody>
                      <tfoot></tfoot>
                    </>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={7}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No matching records found
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
                <div className='d-flex flex-end' style={{gap: '10px'}}>
                  <CardsWidget7
                    className='border border-dashed border-gray-300'
                    stats={totals.grossProfit}
                    description='Gross Profit'
                  />
                  <CardsWidget7
                    className='border border-dashed border-gray-300'
                    stats={totals.grossLoss}
                    description='Gross Loss'
                  />
                </div>
              </div>
              <div className='table-responsive'>
                <table className='table align-middle fs-6  dataTable ' ref={tableRef2}>
                  {data2.length > 0 ? (
                    <>
                      <tbody className='text-gray-600 fw-bold'>
                        {table2.getRowModel().rows.map((row) => (
                          <>
                            <tr
                              key={row.id}
                              onClick={
                                row.getCanExpand() ? row.getToggleExpandedHandler() : undefined
                              }
                              className='bg-hover-light'
                            >
                              {row.getVisibleCells().length > 0 ? (
                                row
                                  .getVisibleCells()
                                  .map((cell) => (
                                    <td key={cell.id}>
                                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                  ))
                              ) : (
                                <td colSpan={7}>
                                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    No matching records found
                                  </div>
                                </td>
                              )}
                            </tr>
                          </>
                        ))}
                      </tbody>
                      <tfoot></tfoot>
                    </>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={7}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No matching records found
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
                <div className='d-flex flex-end' style={{gap: '10px'}}>
                  <CardsWidget7
                    className='border border-dashed border-gray-300'
                    stats={totals.netProfit}
                    description='Net Profit'
                  />
                  <CardsWidget7
                    className='border border-dashed border-gray-300'
                    stats={totals.netLoss}
                    description='Net Loss'
                  />
                </div>
              </div>
            </>
          )}
          {isLoading && <Loader isError={isError} />}
        </KTCardBody>
      </KTCard>
    </>
  )
}

const styles = {
  borderRadius: '0.475rem',
  boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
  backgroundColor: '#fff',
  color: '#7e8299',
  fontWeight: '500',
  margin: '0',
  width: 'auto',
  padding: '1rem 2rem',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 4rem)',
}
