
import { Column } from 'react-table';
import { AgingReportData } from '../API/AgingReport';
import { formatNumber } from '../../../../_metronic/helpers';


const getAgingReportColumns = (activeTab: string): ReadonlyArray<Column<AgingReportData>> => ([
  {
    Header: () => <th className='px-3 min-w-100px col-lg-1'>Parent Ledger</th>,
    accessor: 'parentLedger',
  },
  {
    Header: () => <th className='col-lg-1 min-w-100px'>Sub Ledger</th>,
    accessor: 'subLedger',
    Cell: ({ ...props }) => <div>{props.data[props.row.index].subLedger ?? '-'} </div>,
  },
  {
    Header: () => <th className='col-lg-2 min-w-150px'>{activeTab === 'receivables' ? 'Last Sale Date' : 'Last Purchase Date'}</th>,
    accessor: activeTab === 'receivables' ? 'lastSaleDate' : 'lastPurchaseDate',
  },
  {
    Header: () => <th className='col-lg-2 min-w-125px'>Transaction Amount</th>,
    accessor: 'transactionAmount',
    Cell: ({ ...props }) => <div>{props.data[props.row.index].transactionAmount ? formatNumber(props.data[props.row.index].transactionAmount) : '-'} </div>
  },
  {
    Header: () => <th className='col-lg-2 min-w-125px'>Set Off Amount</th>,
    accessor: 'setOffAmount',
    Cell: ({ ...props }) => <div>{props.data[props.row.index].setOffAmount ? formatNumber(props.data[props.row.index].setOffAmount) : '-'} </div>
  },
  {
    Header: () => <th className='col-lg-2 min-w-125px'>Pending Invoices</th>,
    accessor: 'pendingInvoices',
    Cell: ({ ...props }) => <div>{props.data[props.row.index].pendingInvoices ? formatNumber(props.data[props.row.index].pendingInvoices) : '-'} </div>
  },
  {
    Header: () => <th className='col-lg-2 min-w-125px'>Total Outstanding</th>,
    accessor: 'totalOutstanding',
    Cell: ({ ...props }) => <div>{props.data[props.row.index].totalOutstanding ? formatNumber(props.data[props.row.index].totalOutstanding) : '-'} </div>
  },
  {
    Header: () => <th className='col-lg-2 min-w-125px'>UNRECONCILED BALANCE</th>,
    accessor: 'unReconciledAmount',
    Cell: ({ ...props }) => <div>{props.data[props.row.index].unReconciledAmount ? formatNumber(props.data[props.row.index].unReconciledAmount) : '-'} </div>
  },
  {
    Header: () => <th className='col-lg-2 min-w-125px'>0-15 Days</th>,
    accessor: '0-15 days',
    Cell: ({ ...props }) => <div>{props.data[props.row.index]['0-15 days'] ? formatNumber(props.data[props.row.index]['0-15 days']) : '-'} </div>
  },
  {
    Header: () => <th className='col-lg-2 min-w-125px'>16-30 Days</th>,
    accessor: '16-30 days',
    Cell: ({ ...props }) => <div>{props.data[props.row.index]['16-30 days'] ? formatNumber(props.data[props.row.index]['16-30 days']) : '-'} </div>
  },
  {
    Header: () => <th className='col-lg-2 min-w-125px'>31-60 Days</th>,
    accessor: '31-60 days',
    Cell: ({ ...props }) => <div>{props.data[props.row.index]['31-60 days'] ? formatNumber(props.data[props.row.index]['31-60 days']) : '-'} </div>
  },
  {
    Header: () => <th className='col-lg-2 min-w-125px'>61-90 Days</th>,
    accessor: '61-90 days',
    Cell: ({ ...props }) => <div>{props.data[props.row.index]['61-90 days'] ? formatNumber(props.data[props.row.index]['61-90 days']) : '-'} </div>
  },
  {
    Header: () => <th className='col-lg-2 min-w-125px'>91-180 Days</th>,
    accessor: '91-180 days',
    Cell: ({ ...props }) => <div>{props.data[props.row.index]['91-180 days'] ? formatNumber(props.data[props.row.index]['91-180 days']) : '-'} </div>
  },
  {
    Header: () => <th className='col-lg-2 min-w-125px'>{'>180 Days'}</th>,
    accessor: '>180 days',
    Cell: ({ ...props }) => <div>{props.data[props.row.index]['>180 days'] ? formatNumber(props.data[props.row.index]['>180 days']) : '-'} </div>
  },
]);

export { getAgingReportColumns }