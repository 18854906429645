import {useLocation, Link} from 'react-router-dom'
import {VoucherTypeMap} from '../API/EnterTransactionsAPI'
import {useEffect} from 'react'

const paths = [
  {title: 'Journal', path: '/transactions/enter/journal'},
  {title: 'Receipt', path: '/transactions/enter/receipt'},
  {title: 'Payment', path: '/transactions/enter/payment'},
  {title: 'Contra', path: '/transactions/enter/contra'},
  {title: 'Sales', path: '/transactions/enter/sales'},
  {title: 'Purchase', path: '/transactions/enter/purchase'},
  {title: 'Debit Note', path: '/transactions/enter/debitnote'},
  {title: 'Credit Note', path: '/transactions/enter/creditnote'},
]

export const HeaderTabs: React.FC<{
  setActiveTab: React.Dispatch<React.SetStateAction<keyof VoucherTypeMap>>
}> = ({setActiveTab}) => {
  const location = useLocation()

  useEffect(() => {
    setActiveTab(location.pathname.split('/')[3] as keyof VoucherTypeMap)
  }, [location.pathname, setActiveTab])

  return (
    <div className='card mb-5'>
      <div className='card-body pt-1 pb-1'>
        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {paths.map((p, i) => (
              <li className='nav-item' key={p.path + i}>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === p.path && 'active')
                  }
                  onClick={() => setActiveTab(p.path.split('/')[3] as keyof VoucherTypeMap)}
                  to={p.path}
                >
                  {p.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
