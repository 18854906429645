
import { useMemo, useState } from "react";
import { Currency, CurrencyDetails, createCurrency, getAllCurrencyOptions } from "./api";
import { useLayout } from "../../../../_metronic/layout/core";
import { KTIcon } from "../../../../_metronic/helpers";
import toast from "react-hot-toast";

export const CreateEditCurrency: React.FC<{
  setCreateCurrency: React.Dispatch<React.SetStateAction<boolean>>,
  editCurrency?: Currency
}>
  = ({ setCreateCurrency, editCurrency }) => {
    const { company } = useLayout();
    const [loading, setLoading] = useState(false);
    const [currency, setCurrency] = useState(editCurrency?.currencyCode ? editCurrency.currencyCode : 'INR');
    const [decimal, setDecimal] = useState(4);
    const [currencyOptions, setCurrencyOptions] = useState<CurrencyDetails[]>([]);

    const handleCreateNewGroup = async () => {
      setLoading(true);
      createCurrency({
        dbId: editCurrency?.dbId,
        companyId: company?.value || '',
        currencyCode: currency,
        formalName: currencyOptions.find((c) => c.currency_code === currency)?.currency || '',
        currencySymbol: currencyOptions.find((c) => c.currency_code === currency)?.currency_symbol || '',
        decimal,
      })
        .then((res) => {
          res?.detail && toast.success(res.detail);
          setCurrency('');
          setDecimal(2);
        })
        .catch((err) => err && toast.error(err))
        .finally(() => setLoading(false));
    };

    useMemo(async () => {
      const res = await getAllCurrencyOptions();
      res && setCurrencyOptions(res);
    }, []);

    return (
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'><div
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='Go Back'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            style={{ marginRight: '5px' }}
            onClick={() => setCreateCurrency(false)}
          >
            <KTIcon iconName='arrow-left' className='fs-2' />
          </div>
            <h3 className='fw-bolder m-0'>{!!editCurrency ? 'Update Currency' : 'Add New Currency'}</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Select Currency</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  value={currency}
                  onChange={(e) => setCurrency(e.currentTarget.value)}
                >
                  <option value=''>Select a Currency...</option>
                  {currencyOptions.map((c) =>
                    <option value={c.currency_code}>{c.currency_code}</option>
                  )}
                </select>

              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Formal Name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-disabled form-select-lg fw-bold'
                  value={currency}
                  disabled={true}
                >
                  <option value={currency} >{currencyOptions.find((c) => c.currency_code === currency)?.currency || ''}</option>

                </select>

              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Currency Symbol</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-disabled form-select-lg fw-bold'
                  value={currency}
                  disabled={true}
                >
                  <option value={currency} >{currencyOptions.find((c) => c.currency_code === currency)?.currency_symbol}</option>
                </select>

              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Decimal Precision</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Group name'
                  value={decimal}
                  onChange={(e) => setDecimal(Number(e.target.value))}
                />
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button className="btn btn-secondary" style={{ marginRight: '10px' }} onClick={() => setCreateCurrency(false)} >Cancel</button>
            <button onClick={handleCreateNewGroup} className='btn btn-primary ml-2' disabled={loading}>
              {!loading && !!editCurrency ? 'Save Currency' : 'Add Currency'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

        </div>
      </div>
    )
  }