import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface TrialBalanceResp {
  Expenses: {
    'Direct Expenses': BalanceDetails
    'Indirect Expenses': BalanceDetails
  }
  Assets: {
    'Non-Current Assets': BalanceDetails
    'Current Assets': BalanceDetails
  }
  Liabilities: {
    'Non-Current Liabilities': BalanceDetails
    'Current Liabilities': BalanceDetails
    Equity: BalanceDetails
  }
  Incomes: {
    'Direct Income': BalanceDetails
    'Indirect Income': BalanceDetails
  }
  differenceAmount: string
  totalDebit: string
  totalCredit: string
  differencePercentage: number
}

export interface BalanceDetails {
  group?: string
  subGroup?: string
  ledger?: string
  debit: string | null
  credit: string | null
  children?: BalanceDetails[]
}

export const getTrialBalanceData = async (
  company_id: string,
  fy_name: string,
  from_date: string | undefined,
  to_date: string | undefined
): Promise<TrialBalanceResp | undefined> => {
  try {
    const res = await axios.get(`${API_URL}/accounting/report/trial-balance`, {
      params: {
        company_id,
        fy_name,
        from_date,
        to_date,
      },
    })
    return res.data
  } catch (error: any) {
    console.log(error.message)
    if (error.response) throw error.response.data
  }
}

export interface TrialBalLedgerListParams {
  company_id: string
  fy_name: string
  from_date?: string
  to_date?: string
  parent_ledger: string
  sub_ledger?: string
  parent_group?: string
  sub_group?: string
}

export interface TrialBalLedgerListResp {
  transactionList: TransactionList[]
  parentLedger: string | null
  subLedger: string | null
  parentGroup: string | null
  subGroup: string | null
  toDate: number
  fromDate: string
  openingBalance: number
  closingBalance: number
  debitAmount: number
  creditAmount: number
  transactionType: string
}

export interface TransactionList {
  voucherNumber: number
  voucherType: string | null
  voucherDate: string
  amount: number
  exchangeMultiplier: number
  reportingCurrency: string
  transactingCurrency: string | null
  transactionType: string
  invoiceDetails: InvoiceDetails[]
  transactionDetails: TransactionDetail[]
}

export interface InvoiceDetails {
  invoiceNumber: string
  invoiceAmount: number
  invoiceDescription: string
  settledInvoiceAmount: number
  isInvoiceSettled: boolean
}

export interface TransactionDetail {
  ledgerCode: string
  parentLedger: string
  subLedger: string
  transactionAmount: number
  transactionType: string
}

export const getTrialBalanceLedgersList = async ({
  company_id,
  fy_name,
  ...params
}: TrialBalLedgerListParams): Promise<TrialBalLedgerListResp | undefined> => {
  return await axios
    .get(`${API_URL}/accounting/transactions/ledger-list/${company_id}/${fy_name}`, {
      params,
    })
    .then((response: AxiosResponse<TrialBalLedgerListResp>) => response.data)
    .then((response: TrialBalLedgerListResp) => response)
}

export interface TrialBalGroupListParams {
  company_id: string
  fy_name: string
  parent_group: string
  from_date?: string
  to_date?: string
  sub_group?: string
}
export interface TrialBalanceGroupsListResp {
  ledgerList: LedgerList[]
  parentLedger: string | null
  subLedger: string | null
  parentGroup: string
  subGroup: string | null
  toDate: string
  fromDate: string
}

export interface LedgerList {
  ledgerCode: string
  parentLedger: string
  subLedger: string
  reportingCurrency: string
  transactingCurrency: null | string
  reportingCurrencyAmount: number
  transactingCurrencyAmount: number | null
  transactionType?: string
}

export const getTrialBalanceGroupsList = async ({
  company_id,
  fy_name,
  ...params
}: TrialBalGroupListParams): Promise<TrialBalanceGroupsListResp | undefined> => {
  return await axios
    .get(`${API_URL}/accounting/transactions/ledger-list/${company_id}/${fy_name}`, {
      params,
    })
    .then((response: AxiosResponse<TrialBalanceGroupsListResp>) => response.data)
    .then((response: TrialBalanceGroupsListResp) => response)
}

export interface DownloadTrialBalanceParams {
  company_id: string
  fy_name: string
  from_date?: string
  to_date?: string
}

export const downloadTrialBalance = async ({
  company_id,
  fy_name,
  ...params
}: DownloadTrialBalanceParams): Promise<ArrayBufferLike | undefined> => {
  return await axios
    .get(`${API_URL}/accounting/report/download/trial-balance/${company_id}/${fy_name}`, {
      params,
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    })
    .then((response: AxiosResponse<ArrayBufferLike>) => response.data)
    .then((response: ArrayBufferLike) => response)
}
