import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageTitle } from '../../../_metronic/layout/core'
import { HeaderTabs } from './HeaderTabs'
import { Group } from './group'
import { Ledger } from './ledger'
import { Voucher } from './voucher-type'
import { CurrencyExchange } from './currency'


const Products: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <HeaderTabs />
            <Outlet />
          </>
        }
      >
        <Route
          path='group'
          element={
            <>
              <PageTitle >Group</PageTitle>
              <Group />
            </>
          }
        />
        <Route
          path='ledger'
          element={
            <>
              <PageTitle >Ledger</PageTitle>
              <Ledger />
            </>
          }
        />
        <Route
          path='voucher-type'
          element={
            <>
              <PageTitle >Voucher Type</PageTitle>
              <Voucher />
            </>
          }
        />
        <Route
          path='currency'
          element={
            <>
              <PageTitle >Currency</PageTitle>
              <CurrencyExchange />
            </>
          }
        />
        <Route index element={<Navigate to='/products/group' />} />
      </Route>
    </Routes>
  )
}

export default Products;
