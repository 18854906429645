import {useMemo, useEffect, useState, useRef} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {LedgerList, getTrialBalanceGroupsList} from '../API/TrialBalanceAPI'
import {useLayout, usePageData} from '../../../../_metronic/layout/core'
import {toast} from 'react-hot-toast'
import {KTCard, KTIcon, KTCardBody, formatNumber, useDebounce} from '../../../../_metronic/helpers'
import {Loader} from '../../product-master/loader'
import clsx from 'clsx'
import {ColumnInstance, Row, Column, useTable} from 'react-table'
import jsPDF from 'jspdf'

type Params = {
  parent_group: string
  sub_group?: string
  from?: string
  to?: string
}

const getLedgerListColumns = (
  parent_group: string,
  sub_group?: string,
  from?: string,
  to?: string
): ReadonlyArray<Column<LedgerList>> => [
  {
    Header: () => <th className='min-w-125px ps-4'>Ledger Code</th>,
    accessor: 'ledgerCode',
    Cell: ({...props}) => {
      return <div className='px-3'>{props.data[props.row.index].ledgerCode ?? '-'}</div>
    },
  },
  {
    Header: () => <th className='min-w-125px ps-4'>Parent Ledger</th>,
    accessor: 'parentLedger',
    Cell: ({...props}) => {
      return <div className='px-3'>{props.data[props.row.index].parentLedger ?? '-'}</div>
    },
  },
  {
    Header: () => <th className='min-w-125px ps-4'>Sub Ledger</th>,
    accessor: 'subLedger',
    Cell: ({...props}) => (
      <div className='px-3 '>{props.data[props.row.index].subLedger ?? '-'}</div>
    ),
  },
  {
    Header: () => <th className='min-w-125px'>Closing Balance</th>,
    accessor: 'reportingCurrencyAmount',
    Cell: ({...props}) => (
      <div className='text-dark'>
        {props.data[props.row.index].reportingCurrencyAmount === null
          ? '-'
          : `${props.data[props.row.index].reportingCurrency} ${formatNumber(
              props.data[props.row.index].reportingCurrencyAmount
            )}`}{' '}
      </div>
    ),
  },
  {
    Header: () => <th className='min-w-125px'>Actions</th>,
    accessor: 'transactionType',
    Cell: ({...props}) => {
      const navigate = useNavigate()
      let url = `/transactions/trial-balance/ledger?parent_ledger=${
        props.data[props.row.index].parentLedger
      }&parent_group=${parent_group}`
      sub_group && (url += `&sub_group=${sub_group}`)
      from && (url += `&from=${from}`)
      to && (url += `&to=${to}`)

      return (
        <div className='d-flex justify-content-start flex-shrink-0'>
          <div
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='View Transactions'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => navigate(url)}
          >
            <KTIcon iconName='arrow-right' className='fs-2' />
          </div>
        </div>
      )
    },
  },
]

export const GroupAndSubGroupDetails = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {company, financialYear} = useLayout()
  const {setPageTitle} = usePageData()
  const tableRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState<LedgerList[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')

  const {parent_group, sub_group, from, to} = useMemo(() => {
    let obj: Params = {
      parent_group: '',
      sub_group: '',
      from: '',
      to: '',
    }

    location.search
      .substring(1)
      .split('&')
      .map((p) => {
        const val = p.split('=')
        obj[val[0] as keyof Params] = decodeURI(val[1])
        return null
      })

    return obj
  }, [location.search])

  useEffect(() => {
    setPageTitle('Group Transactions')
  }, [setPageTitle])

  const columns = useMemo(
    () => getLedgerListColumns(parent_group, sub_group, from, to),
    [from, parent_group, sub_group, to]
  )

  useEffect(() => {
    const getData = async () => {
      if (company.value && financialYear?.value) {
        setIsError(false)
        setIsLoading(true)
        await getTrialBalanceGroupsList({
          company_id: company.value,
          fy_name: financialYear.value,
          from_date: from,
          to_date: to,
          parent_group: parent_group,
          sub_group: sub_group,
        })
          .then((res) => res && setData(res.ledgerList))
          .catch((err) => {
            toast.error(err.detail || 'Something went wrong. Please try again later')
            setIsError(true)
          })
          .finally(() => setIsLoading(false))
      }
    }

    getData()
  }, [company.value, financialYear.value, from, parent_group, sub_group, to])

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const filteredData: LedgerList[] = useMemo(() => {
    if (debouncedSearchTerm?.length) {
      return data.filter(
        (d) =>
          d.parentLedger.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d.subLedger.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d?.transactionType?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d?.ledgerCode?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      )
    }
    return []
  }, [debouncedSearchTerm, data])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: debouncedSearchTerm?.length ? filteredData : data,
  })

  return (
    <KTCard>
      <div className='card-header border-0 pt-3'>
        <div className='card-title'>
          <div
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='Go Back'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            style={{marginRight: '5px'}}
            onClick={() => navigate(-1)}
          >
            <KTIcon iconName='arrow-left' className='fs-2' />
          </div>
          <div className=' text-dark fs-2 my-1 fw-bolder'>
            {parent_group} {sub_group ? ` | ${sub_group}` : ''}
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-3'>
        <div className='card-title'>
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className='card-toolbar'>
          {/* begin::Group actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => {
                toast.success('Download has been started successfully')
                const doc = new jsPDF({
                  format: 'a2',
                  unit: 'px',
                  hotfixes: ['px_scaling'],
                })

                tableRef.current &&
                  doc.html(tableRef.current, {
                    callback: (doc) => {
                      const pageWidth = doc.internal.pageSize.getWidth()
                      const pageHeight = doc.internal.pageSize.getHeight()

                      const header = `${company.label} | ${financialYear.value}`
                      doc.setFontSize(26)
                      doc.setFont('Arial', 'italic', 600)
                      doc.text(header, (pageWidth - 300) / 2, 50)

                      const footer = `${new Date().getFullYear().toString()}© String Labs`
                      doc.setFontSize(20)
                      doc.text(footer, 30, pageHeight - 20 - 10)
                      const totalPages = doc.getNumberOfPages()

                      doc.deletePage(totalPages - 1)
                      doc.deletePage(totalPages - 2)
                      doc.deletePage(totalPages - 4)
                      doc.deletePage(totalPages - 3)

                      doc.save(`Group wise Transactions - ${company.label}(${financialYear.value})`)
                    },
                    margin: [80, 30, 30, 30],
                    autoPaging: 'text',
                  })
              }}
            >
              <KTIcon iconName='arrow-down' className='fs-2' />
              Download
            </button>
            {/* end::Add user */}
          </div>
          {/* end::Group actions */}
        </div>
        {/* end::Card toolbar */}
      </div>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            ref={tableRef}
            id='group_sub_group_table'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<LedgerList>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<LedgerList>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {isLoading && <Loader isError={isError} />}
      </KTCardBody>
    </KTCard>
  )
}

type ColProps = {
  column: ColumnInstance<LedgerList>
}

export const CustomHeaderColumn: React.FC<ColProps> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

type RowProps = {
  row: Row<LedgerList>
}

export const CustomRow: React.FC<RowProps> = ({row}) => {
  return (
    <>
      <tr {...row.getRowProps()} className='bg-hover-light'>
        {row.cells.map((cell) => {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
            >
              {cell.render('Cell')}
            </td>
          )
        })}
      </tr>
    </>
  )
}

export const styles = {
  borderRadius: '0.475rem',
  boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
  backgroundColor: '#fff',
  color: '#7e8299',
  fontWeight: '500',
  margin: '0',
  width: 'auto',
  padding: '1rem 2rem',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 4rem)',
}
