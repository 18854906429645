import clsx from 'clsx'
import {ColumnInstance, Row} from 'react-table'
import {Transaction} from '../API/ViewTransactionsAPI'
import {formatNumber} from '../../../../_metronic/helpers'

type ColProps = {
  column: ColumnInstance<Transaction>
}

export const CustomHeaderColumn: React.FC<ColProps> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

type RowProps = {
  row: Row<Transaction>
}

export const CustomRow: React.FC<RowProps> = ({row}) => {
  return (
    <>
      <tr {...row.getRowProps()} className='bg-hover-light'>
        {row.cells.map((cell, i) => {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
            >
              {cell.render('Cell')}
            </td>
          )
        })}
      </tr>
      {row.original.transactionDetails.map((tr, i) => (
        <tr
          key={i}
          className={
            i + 1 === row.original.transactionDetails.length
              ? 'border-bottom border-gray-900 bg-hover-light'
              : 'bg-hover-light'
          }
        >
          <td colSpan={4}></td>
          <td className='p-0 '>
            <em className='d-flex text-center align-content-center justify-content-center'>
              <span className='text-dark fw-bolder'>{tr.parentLedger}&nbsp;&nbsp;</span>
              <span className='text-dark fw-semibold'>{tr.subLedger && ` - ${tr.subLedger} `}</span>
              <span className={tr.transactionType === 'credit' ? 'text-success' : 'text-danger'}>
                {row.original.reportingCurrency} {formatNumber(tr.transactionAmount)}
                {tr.transactionType === 'credit' ? ' CR' : ' DR'}
                {row.original.reportingCurrency !== row.original.transactingCurrency &&
                  `  (${row.original.transactingCurrency} ${formatNumber(
                    row.original.totalAmount / row.original.exchangeMultiplier
                  )})`}
              </span>
            </em>
          </td>
          <td></td>
          <td></td>
        </tr>
      ))}
    </>
  )
}

export const styles = {
  borderRadius: '0.475rem',
  boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
  backgroundColor: '#fff',
  color: '#7e8299',
  fontWeight: '500',
  margin: '0',
  width: 'auto',
  padding: '1rem 2rem',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 4rem)',
}
