import Lottie from 'lottie-react'
import * as earth from './Animation - 1716795499887.json'
import {Error404} from '../errors/components/Error404'
import {Error500} from '../errors/components/Error500'
import {useLoaderContext} from './LoaderProvider'
function Preloader(): any {
  const {error, loading} = useLoaderContext()
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    // padding: '16rem 2rem',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  }

  return (
    <div
      style={{
        ...styles,
        position: 'absolute',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {loading && (
        <Lottie
          animationData={earth}
          loop
          autoPlay
          style={{
            height: '30rem',
            // width: '100%',
          }}
        />
      )}
      {error && <Error500 />}
    </div>
  )
}

export default Preloader
